import { get } from 'lodash';
import {
  PMV1ProductConfigResponse,
  PMV2ProductConfigResponse,
  RoutingRequestBodyItem,
  RoutingRequestResponse,
} from '../types';

type ProductDetails = {
  productManagerUrl: string;
  productVersion: string;
};

const isPMV2Product = (
  productConfigJson: PMV1ProductConfigResponse | PMV2ProductConfigResponse,
): productConfigJson is PMV2ProductConfigResponse => {
  /**
   * Only PM v2 products have a `productVersion` in the product configuration JSON, so we
   * use that to determine the JSON type
   */
  return (productConfigJson as PMV2ProductConfigResponse).productVersion !== undefined;
};

const getProductConfigJson = async (
  productConfigUrl: string | undefined,
): Promise<PMV1ProductConfigResponse | PMV2ProductConfigResponse | undefined> => {
  if (!productConfigUrl) {
    return;
  }

  const res = await fetch(productConfigUrl);

  if (!res.ok) {
    return;
  }

  return await res.json();
};

export const getProductVersionAndManagerUrlByProductVersion = async (
  productConfigUrl: string | undefined,
): Promise<ProductDetails | undefined> => {
  if (!productConfigUrl) {
    return;
  }

  const productConfigJson = await getProductConfigJson(productConfigUrl);
  if (!productConfigJson) {
    return;
  }

  const { mcpSku } = productConfigJson;

  if (isPMV2Product(productConfigJson)) {
    return {
      productManagerUrl: `https://productmanager-v2.products.cimpress.io/product-details-v2/${mcpSku}/versions/${productConfigJson.productVersion}`,
      productVersion: `v${productConfigJson.productVersion}`,
    };
  } else {
    // is a PM v1 product
    return {
      productManagerUrl: `https://productmanager.products.cimpress.io/rules/product/edit?mcpSku=${mcpSku}`,
      productVersion: 'v1',
    };
  }
};

export const getProductConfigUrlFromRoutingRequest = (
  routingRequestJson: RoutingRequestResponse,
  itemId: string,
): string | undefined => {
  const initialItem = get(routingRequestJson, ['requestBody', 'items']).find(
    (item: RoutingRequestBodyItem) => item.itemId === itemId,
  );

  if (!initialItem) {
    return;
  }

  return get(initialItem, ['_embedded', 'ownedItem', '_links', 'productConfiguration', 'href']);
};
