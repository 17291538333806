import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import {
  Environment,
  FailedDecision,
  InvalidConsideredOption,
  SuccessfulDecision,
  Option,
  Item,
  RoutingConfigurationDetails,
} from '../../types';
import { Spinner, colors } from '@cimpress/react-components';
import IconCheckCircleAlt from '@cimpress-technology/react-streamline-icons/lib/IconCheckCircleAlt';
import SuspendedOrdersDecisionDetail from '../SuspendedOrdersItem/SuspendedOrdersDecisionDetail';
import { getDecision } from '../../apis/orca';
import { getItemByItemId } from '../../apis/item-service';
import SuspendedOrdersItemOptions from '../SuspendedOrdersItem/SuspendedOrdersItemOptions';
import { isOptionEqualTo } from '../../helpers/IsOptionEqualTo';
import SuspendedOrdersItemHeaderInfo from '../SuspendedOrdersItem/SuspendedOrdersItemHeaderInfo';
import { Accordion } from '@cimpress/react-components';
import { getFulfillmentConfigName } from '../../helpers/optionGetters';
import { RoutingDetails } from '../shared/RoutingDetails';
import { getRoutingRequest } from '../../apis/request-manager';
import { getRoutingConfig } from '../../helpers/routingConfig';
import { getMcpProductByMcpSku } from '../../apis/product-introduction';

const { silver, success } = colors;

export type DecisionViewerProps = {
  accessToken: string;
  environment: Environment;
  decisionId: string;
};

const headerStyle = {
  backgroundColor: 'white',
  position: 'relative',
} as React.CSSProperties;

const bodyStyle = {
  backgroundColor: 'white',
  padding: '0 16px 16px',
};

const globalFulfillerIdStyle = {
  display: 'flex',
  alignItems: 'center',
  textAlign: 'end' as const, // TS type narrowing doesn't recognize any values for TextAlign property without "as const"
  gap: '8px',
};

const accordionStyle = {
  marginBottom: '10px',
  marginLeft: '0px',
  marginRight: '0px',
  border: `1px solid ${silver}`,
};

const DecisionViewer: React.FC<DecisionViewerProps> = (props) => {
  const { accessToken, environment, decisionId } = props;

  const [decision, setDecision] = useState<SuccessfulDecision | FailedDecision>();
  const [item, setItem] = useState<Item>();
  const [options, setOptions] = useState<Option[]>();
  const [message, setMessage] = useState<string>('');
  const [messageType, setMessageType] = useState<'error' | 'info' | 'success' | ''>('');
  const [configuration, setConfiguration] = useState<RoutingConfigurationDetails>(undefined);
  const [chosenOptionId, setChosenOptionId] = useState<string | null>(null);
  const [buyer, setBuyer] = useState<string | null>(null);

  const convertConsideredOptionToOption = (option: Option | InvalidConsideredOption): Promise<Option> => {
    return new Promise(async (resolve) => {
      const mcpProduct = await getMcpProductByMcpSku(environment, accessToken, option.referenceId);

      // Some invalid options don't have a fulfiller ID, so we need to try to get one
      const fulfillerId = option.fulfillerId || mcpProduct.fulfillerId;
      option.fulfillerName = await getFulfillmentConfigName(environment, accessToken, { ...option, fulfillerId });

      if ('isValid' in option) {
        resolve(option);
      }

      resolve({
        productConfigurationUrl: option.productConfigurationUrl,
        fulfillerId,
        optionId: option.optionId,
        referenceId: option.referenceId,
        problem: option.problem,
        isValid: false,
        _links: {
          productConfiguration: {
            href: option.productConfigurationUrl,
          },
        },
      } as Option);
    });
  };

  async function fetchDecisionDetails() {
    try {
      const decision = await getDecision(decisionId, accessToken, environment);
      const options = decision.consideredOptions
        ? await Promise.all(decision.consideredOptions.map((o) => convertConsideredOptionToOption(o)))
        : undefined;

      const item = await getItemByItemId(decision.itemId, accessToken, environment);
      const routingConfigDetails = await getRoutingConfig(decision, environment, accessToken, item);

      setOptions(options);
      setDecision(decision);
      setItem(item);
      setConfiguration(routingConfigDetails);

      if ('chosenOptionId' in decision) {
        setChosenOptionId(decision.chosenOptionId);
      }
    } catch (err: any) {
      setMessageType('error');
      setMessage(err.message);
    }
  }

  useEffect(() => {
    if (!decision || !item) {
      return;
    }

    const getBuyer = async () => {
      if (get(item, ['_links', 'routingRequest'])) {
        const itemRoutingRequestResponse = await getRoutingRequest(item._links.routingRequest.href);

        if (itemRoutingRequestResponse) {
          setBuyer(get(itemRoutingRequestResponse, 'buyer', null));
        }
      }
    };

    getBuyer();
  }, [decision, item]);

  let body = null;
  if (decision) {
    const selectedOption = options?.find((option) => option.optionId === chosenOptionId);
    const fulfillerName =
      selectedOption && selectedOption.fulfillerName ? selectedOption.fulfillerName : item?.globalFulfillerId;

    const header = (
      <div className="header-main">
        <div className="header-left">
          <SuspendedOrdersItemHeaderInfo
            item={item || ({} as Item)}
            decision={decision}
            attemptedDecisionFetch={true}
          />
        </div>
        <div className="header-right">
          {fulfillerName ? (
            <div style={globalFulfillerIdStyle}>
              <IconCheckCircleAlt color={success.base} weight="fill" />
            </div>
          ) : null}
        </div>
      </div>
    );

    const accordionBody = (
      <div>
        <SuspendedOrdersDecisionDetail decision={decision} />
        <RoutingDetails item={item} configuration={configuration} buyer={buyer} />
        <h2>Available Options</h2>
        {options ? (
          <SuspendedOrdersItemOptions
            accessToken={accessToken}
            environment={environment}
            options={options}
            item={item || ({} as Item)}
            isOptionEqualTo={isOptionEqualTo}
            existingPick={null}
            chosenOptionId={chosenOptionId}
            onOptionSelection={undefined}
          />
        ) : null}
      </div>
    );

    body = (
      <Accordion
        defaultOpen={true}
        className="options-list"
        title={header}
        variant="default"
        onHeaderClick={undefined}
        style={accordionStyle}
        headerStyle={headerStyle}
        bodyStyle={bodyStyle}
      >
        {accordionBody}
      </Accordion>
    );
  } else if (messageType == '') {
    fetchDecisionDetails();
    body = (
      <div style={{ marginTop: '10px' }}>
        <RoutingDetails item={item} configuration={configuration} buyer={buyer} />
        <h2>Available Options</h2>
        <br />
        <Spinner />
      </div>
    );
  } else if (messageType === 'error') {
    body = (
      <div>
        Error retrieving decision: {message} for {decisionId}
      </div>
    );
  } else {
    body = null;
  }

  return <div className="suspended-orders-item">{body}</div>;
};

export default DecisionViewer;
