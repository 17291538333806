import React, { useCallback, useState } from 'react';
import SuspendedOrdersItem, { isSuspendedInRouting } from '../SuspendedOrdersItem/SuspendedOrdersItem';
import { Environment, Item } from '../../types';
import { Accordion, Alert, Button, Modal, colors } from '@cimpress/react-components';
import { retryRoutingRequest } from '../../apis/request-manager';
import './SuspendedOrderItemsTable.css';
import * as bluebird from 'bluebird';

const { silver } = colors;

export type SuspendedOrderItemsTableProps = {
  environment: Environment;
  accessToken: string;
  userProfile: any;
  order: {
    items: Item[];
    retryLink: string;
  };
  bodyOnly?: boolean;
  isModal?: boolean; // isShowingModal and closeModal required if this is true
  isShowingModal?: boolean;
  closeModal?: () => void;
};

const SuspendedOrderItemsTable: React.FC<SuspendedOrderItemsTableProps> = (props) => {
  const [isSendingRemediation, setIsSendingRemediation] = useState<boolean | null>(null);
  const [message, setMessage] = useState<string>('');
  const [messageType, setMessageType] = useState<'error' | 'info' | 'success'>('info');
  const [submitButtonLabel, setSubmitButtonLabel] = useState<string>('Retry all');

  const { accessToken, userProfile, environment, order, bodyOnly, isModal, isShowingModal, closeModal } = props;

  const [items, setItems] = useState<Item[]>(order.items);

  const putFailedDecisionsFirst = (itemId: string, hasDecisionProblem: boolean) => {
    if (hasDecisionProblem) {
      const firstItem = items.filter((item) => {
        return item.itemId === itemId;
      });

      const itemsWithoutFirstItem = items.filter((item) => {
        return item.itemId !== itemId;
      });

      setItems(firstItem.concat(itemsWithoutFirstItem));
    }
  };

  const setLabel = (label: string) => {
    setSubmitButtonLabel(label);
  };

  const fetchItems = async (items: Item[]): Promise<Item[]> => {
    const newItemsPromises: Promise<Item>[] = items.map(async (item): Promise<Item> => {
      const url = item._links?.self?.href;

      const res = await fetch(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
        method: 'GET',
      });

      if (res.ok) {
        return await res.json();
      } else {
        throw new Error(`Error retrieving item. Received status code ${res.status}.`);
      }
    });

    const newItems: Promise<Item[]> = bluebird.Promise.map(
      newItemsPromises,
      (newItem: Item) => {
        return newItem;
      },
      { concurrency: 10 },
    );

    return newItems;
  };

  const sendRemediation = useCallback(async () => {
    const successMessage = 'Routing request retried successfully.';

    if (isSendingRemediation) {
      return;
    }

    setIsSendingRemediation(true);

    try {
      // I should probably do this, but first confirm exactly what sendremediation does
      // if (submitButtonLabel === 'Submit all') {
      //     yield createRemediation(environment, accessToken, {
      //         remediations: [
      //             {
      //                 chosenProductUrl: selectedOption.productConfigUrl,
      //                 itemId: selectedOption.itemId,
      //                 fulfiller: selectedOption.fulfillerId,
      //             },
      //         ],
      //     });
      // }

      if (items && items[0]?._links?.routingRequest?.name) {
        await retryRoutingRequest(environment, accessToken, items[0]?._links?.routingRequest?.name);
        setMessageType('success');
        setMessage(successMessage);
      } else {
        throw new Error(`Error sending remediation`); // add response code
      }

      setMessageType('success');
      setMessage(successMessage);
    } catch (err: any) {
      setMessageType('error');
      setMessage(err.message);
    } finally {
      setIsSendingRemediation(false);
      await fetchItems(items).then((newItems) => {
        setItems(newItems);
      });
    }
  }, [isSendingRemediation]);

  const accordionStyle: React.CSSProperties = {
    position: 'relative',
    border: `1px solid ${silver}`,
    padding: '10px',
  };

  const bodyStyle: React.CSSProperties = {
    padding: '10px',
  };

  let retryStatus = <div></div>;
  if (message) {
    let icon: React.ReactNode = null;
    let iconClassName = '';

    switch (messageType) {
      case 'error':
        icon = <Alert message={message} status="warning" dismissible={false} style={{ padding: '4px' }} />;
        iconClassName = 'text-danger';
        break;
      case 'info':
        icon = <Alert message={message} status="info" dismissible={false} style={{ padding: '4px' }} />;
        iconClassName = 'text-info';
        break;
      case 'success':
        icon = <Alert message={message} status="success" dismissible={false} style={{ padding: '4px' }} />;
        iconClassName = 'text-success';
        break;
    }

    retryStatus = (
      <div
        className="text-center"
        style={{
          zoom: '0.85',
          position: 'absolute',
          right: '200px',
          top: '12pt',
          display: 'flex',
          maxWidth: '70%',
        }}
      >
        <span className={iconClassName}>{icon}</span>
      </div>
    );
  }

  const accordionHeader = (
    <div style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
      <div>Items</div>
      {retryStatus}
    </div>
  );

  const embeddedComponent = bodyOnly ? (
    <div style={bodyStyle}>
      {items.map((item) => (
        <SuspendedOrdersItem
          key={item.itemId}
          item={item}
          accessToken={accessToken}
          userProfile={userProfile}
          environment={environment}
          putFailedDecisionsFirst={putFailedDecisionsFirst}
          setItemsTableLabel={setLabel}
          defaultOpen={false}
        />
      ))}
    </div>
  ) : (
    <Accordion title={accordionHeader} variant="minimal" style={accordionStyle} bodyStyle={bodyStyle}>
      {items.map((item) =>
        isSuspendedInRouting(item) ? (
          <div className="retry-btn" key={`retry-btn-${item.itemId}`}>
            <Button variant="default" disabled={!!isSendingRemediation} onClick={sendRemediation}>
              {submitButtonLabel}
            </Button>
            {/* <SuspendedOrdersRefreshButton/> */}
          </div>
        ) : null,
      )}
      {items.map((item) => (
        <SuspendedOrdersItem
          key={item.itemId}
          item={item}
          accessToken={accessToken}
          userProfile={userProfile}
          environment={environment}
          putFailedDecisionsFirst={putFailedDecisionsFirst}
          setItemsTableLabel={setLabel}
          defaultOpen={false}
        />
      ))}
    </Accordion>
  );

  return isModal && isShowingModal !== undefined ? (
    <Modal
      className="decision-viewer-modal"
      style={{ width: '1000px' }}
      show={isShowingModal}
      onRequestHide={closeModal}
      closeOnOutsideClick={true}
    >
      {embeddedComponent}
      <Button variant="default" onClick={closeModal}>
        Close
      </Button>
    </Modal>
  ) : (
    embeddedComponent
  );
};

export default SuspendedOrderItemsTable;
