import {
  Environment,
  FailedDecision,
  Item,
  LinkWithName,
  RoutingConfigurationDetails,
  SuccessfulDecision,
} from '../types';

const getRoutingConfigUiBaseUrl = (environment: Environment) => {
  if (environment === 'int') {
    return 'https://int-routing-configuration.cimpress.io';
  }

  return 'https://routing-configuration.cimpress.io';
};

const getNomsUrl = (environment: Environment, networkOperator: string, mcpSku: string) => {
  return environment === 'int'
    ? `https://int-noms.lynx.cimpress.io/accounts/${networkOperator}/configurations/${mcpSku}`
    : `https://noms.lynx.cimpress.io/accounts/${networkOperator}/configurations/${mcpSku}`;
};

const getStrategyDetails = async (strategyUrl: string, accessToken: string): Promise<string | undefined> => {
  const strategyDetails = await fetch(strategyUrl, {
    headers: { Authorization: `Bearer ${accessToken}` },
    method: 'GET',
  });

  if (strategyDetails.ok) {
    const strategyData = await strategyDetails.json();
    return strategyData.name;
  }
  return undefined;
};

const getConfigurationLink = (routingConfig: LinkWithName | undefined, environment: Environment) => {
  if (undefined) {
    return;
  }

  return `${getRoutingConfigUiBaseUrl(environment)}/configurations/${routingConfig?.name}`;
};

export const getRoutingConfig = async (
  decision: SuccessfulDecision | FailedDecision,
  environment: Environment,
  accessToken: string,
  item: Item,
): Promise<RoutingConfigurationDetails> => {
  let routingConfigDetails: RoutingConfigurationDetails = undefined;
  if (decision._links?.routingConfiguration) {
    routingConfigDetails = {
      name: 'View configuration',
      href: getConfigurationLink(decision._links.routingConfiguration, environment),
    };
  } else if (decision.strategyUrl && decision._links?.networkOperator) {
    routingConfigDetails = {
      name: await getStrategyDetails(decision.strategyUrl, accessToken),
      href: getNomsUrl(environment, decision._links?.networkOperator?.name, item.orderedSkuCode),
    };
  }

  return routingConfigDetails;
};
