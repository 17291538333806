import * as React from 'react';
import { white } from '../colors';

/* eslint-disable max-len */
const SuccessSvg = (props: { [otherOptions: string]: unknown }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="currentColor"
    color={white}
    {...props}
  >
    <path d="M24 12c0 6.6-5.4 12-12 12S0 18.6 0 12 5.4 0 12 0s12 5.4 12 12zm-13.4 6.4 8.9-8.9c.3-.3.3-.8 0-1.1l-1.1-1.1c-.3-.3-.8-.3-1.1 0L10 14.6l-3.4-3.4c-.3-.3-.8-.3-1.1 0l-1.1 1.1c-.3.3-.3.8 0 1.1l5 5c.4.3.9.3 1.2 0z" />
  </svg>
);

export default SuccessSvg;
