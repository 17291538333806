import React, { Children, DetailedHTMLProps, HTMLAttributes, ReactElement } from 'react';
import { css, cx } from '@emotion/css';
import { StepGroup, StepGroupProps } from './StepGroup';
import { StepProps } from './Step';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';
import { useFeatureFlags } from './FeatureFlags';

export interface StepperProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    PublicComponentProps {
  typeName?: string;

  /**
   * Tells the stepper which step should be active. Pass either the index of the step which should be active or its name.
   */
  activeStep?: number | string;

  /**
   * Tells the stepper which validation state colors to use for the active step.
   */
  status: 'success' | 'warning' | 'error' | 'info';

  /**
   * Children of the stepper should be `<Step>` elements.
   */
  children: ReactElement[];

  /**
   * Tells the stepper that the currently active step is only partially complete - will display a half-filled circle.
   * Avoid using unless you have a compelling UX reason.
   */
  partial?: boolean;

  /**
   * Condenses stepper padding for small steppers. Additional children of Step components will not be shown on small steppers.
   */
  small?: boolean;

  /**
   * Inline styles to be applied to the root stepper div.
   */
  vertical?: boolean;
}

interface ControlProps {
  index: number;
  active?: boolean;
  partial?: boolean;
  completed?: boolean;
  activeColor: string;
  small: boolean;
  vertical: boolean;
  activeSubStep?: string | number;
  connectorColor?: string;
}

export const Stepper = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  typeName = 'Stepper',
  children,
  activeStep = 0,
  small = false,
  color,
  partial = false,
  vertical = false,
  className = '',
  style = {},
  status = 'success',
  ...rest
}: StepperProps): JSX.Element => {
  const { v17_noOuterSpacing } = useFeatureFlags();
  const activeColor = color || cvar(`color-background-${status}`);
  const totalSteps = Children.count(children);
  const steps = Children.map(children, (step: ReactElement<StepProps | StepGroupProps>, index: number) => {
    const last = index === totalSteps - 1;
    const controlProps: ControlProps = { index, activeColor, small, vertical };

    // Extended from https://github.com/callemall/material-ui/blob/master/src/Stepper/Stepper.js
    const activeSteps = activeStep.toString().split('.');
    const activeIndex = parseInt(activeSteps[0], 10);
    controlProps.activeSubStep = activeSteps[1] ? parseInt(activeSteps[1], 10) : undefined;
    const {
      props: { overrideStatus },
    } = step;

    // Index is Greater than Active Index -> Alloy Connector
    const connectorColor =
      index >= activeIndex
        ? cvar('color-icon-disabled') // alloy
        : overrideStatus
        ? // Index is Less than Active and Override exists -> Override Status Color
          cvar(`color-background-${overrideStatus}`)
        : // Index is Less than Active and No Override -> Success
          cvar('color-background-success');

    controlProps.connectorColor = connectorColor;

    const connectorVertical = css`
      flex: 1 1 auto;
      border-right: 2px solid ${connectorColor};
      padding-right: ${small ? cvar('spacing-4') : cvar('spacing-8')};
      margin: ${cvar('spacing-4')} 0;
      flex-direction: column;
    `;
    const connectorHorizontal = css`
      flex: 1 1 auto;
      border-top: 2px solid ${connectorColor};
      margin-top: ${small ? cvar('spacing-4') : cvar('spacing-8')};
    `;

    const Connector = ({ connectorClassName }: { connectorClassName: string }) => (
      <div className={cx(connectorClassName)} />
    );
    const connector = vertical ? (
      <Connector connectorClassName={connectorVertical} />
    ) : (
      <Connector connectorClassName={connectorHorizontal} />
    );

    if (activeIndex === index) {
      controlProps.active = true;
      controlProps.partial = partial;
    } else if (activeIndex > index) {
      controlProps.completed = true;
    } else if (activeIndex < index) {
      // do we want to add anything here?
    }

    const cloneProps = {
      ...controlProps,
      ...step.props,
      index: controlProps.index,
    };
    return [React.cloneElement(step, cloneProps), !last && !(step.type === StepGroup) && connector];
  });

  const stepperVertical = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: fit-content;
    line-height: 1.286;
  `;

  const stepperHorizontal = css`
    display: flex;
    justify-content: space-between;
    padding: 14px 0 0;
    ${!v17_noOuterSpacing && `margin-bottom: 30px;`};
  `;

  const stepperStyle = vertical ? stepperVertical : stepperHorizontal;

  return (
    <div className={cx('crc-stepper', stepperStyle, className)} style={{ ...style }} {...rest}>
      {steps}
    </div>
  );
};
