import { SuccessfulDecision, FailedDecision, Environment } from '../types';

export type GetDecisionsSearchParams = {
  orderId?: string;
  itemId?: string;
};

const getBaseUrl = (environment: Environment): string => {
  if (environment === 'int') {
    return 'https://int-orca.lynx.cimpress.io';
  } else {
    return 'https://orca.lynx.cimpress.io';
  }
};

export async function getDecisions(
  environment: Environment,
  accessToken: string,
  params: GetDecisionsSearchParams,
): Promise<SuccessfulDecision[] | FailedDecision[]> {
  const url = new URL(getBaseUrl(environment));
  url.pathname = '/v1/decisions';

  const querystring: Record<string, string> = {};
  if (params.orderId) {
    querystring.orderId = params.orderId;
  }

  if (params.itemId) {
    querystring.itemId = params.itemId;
  }

  url.search = new URLSearchParams(querystring).toString();
  const res = await fetch(url.toString(), { headers: { Authorization: `Bearer ${accessToken}` }, method: 'GET' });

  if (!res.ok) {
    throw new Error(`Error retrieving decisions. Received status code ${res.status}.`);
  }

  return res.json();
}

export async function getDecision(
  decisionId: string,
  accessToken: string,
  environment: Environment,
): Promise<SuccessfulDecision | FailedDecision> {
  const url = `${getBaseUrl(environment)}/v1/decisions/${decisionId}`;

  const res = await fetch(url, { headers: { Authorization: `Bearer ${accessToken}` }, method: 'GET' });

  if (!res.ok) {
    throw new Error(`Error retrieving decisions. Received status code ${res.status}.`);
  }

  return res.json();
}
