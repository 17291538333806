import { get } from 'lodash';
import { Environment, InvalidConsideredOption, Option } from '../types';
import { getFulfillerIdentityByFulfillerId } from '../apis/fulfiller-identity';

export const getFulfillmentConfigName = async (
  environment: Environment,
  accessToken: string,
  option: Option | InvalidConsideredOption,
): Promise<string | undefined> => {
  // The baseFulfillmentConfiguration comes from the considered options on the routing decision
  const baseFulfillmentConfigurationUrl = get(option, ['_links', 'baseFulfillmentConfiguration', 'href']);

  if (baseFulfillmentConfigurationUrl) {
    const fulfillmentConfigVersionResponse = await fetch(baseFulfillmentConfigurationUrl);

    if (fulfillmentConfigVersionResponse.ok) {
      const fulfillmentConfigVersionBody = await fulfillmentConfigVersionResponse.json();
      return get(fulfillmentConfigVersionBody, ['name']);
    } else {
      // Try to get the name from the fulfiller identity service instead
      // This path may be hit for users who don't have permission to read the fulfillment config
      return get(await getFulfillerIdentityByFulfillerId(environment, accessToken, option.fulfillerId), 'name');
    }
  } else {
    // Invalid considered options don't have a base fulfillment configuration link, so we try to get the name
    // from the fulfiller identity service instead.
    return get(await getFulfillerIdentityByFulfillerId(environment, accessToken, option.fulfillerId), 'name');
  }
};
