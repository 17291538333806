import * as React from 'react';
import { white } from '../colors';

/* eslint-disable max-len */
const ErrorSvg = (props: { [otherOptions: string]: unknown }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="currentColor"
    color={white}
    {...props}
  >
    <path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm4.624 4.66L5.013 16.272l2.68 2.68L19.304 7.34l-2.68-2.68z" />
  </svg>
);

export default ErrorSvg;
