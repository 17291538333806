/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */

import React from 'react';
import { TableCell } from './TableCell';
import { TableColumnProps } from '../types';

export interface TableBodyProps {
  data: any[];
  columns: TableColumnProps[];
}

const TableBody: React.FC<TableBodyProps> = ({ data, columns }) => (
  <>
    {data.map((row: any, i: number) => (
      <tr key={`row-${row.id}-${i}`}>
        {columns.map(c => (
          <TableCell key={`cell-${row.id}-${i}-${c.accessor}`} column={c} row={row} />
        ))}
      </tr>
    ))}
  </>
);

export { TableBody };
