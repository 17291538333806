import './SuspendedOrdersItemOption.css';

import React, { useEffect, useState } from 'react';
import { Accordion, Button, Spinner, Tooltip, colors } from '@cimpress/react-components';
import { ShipmentPlanViewer } from '@cimpress-technology/shipment-planner-component';
import { Cause, Item, ExistingPick, Option, Costs, OptionEqualityHandler, Environment } from '../../types';
import { getFcUiLink } from '../../apis/fulfillment-configuration';
import { isSuspendedInRouting, OptionSelectionHandler } from './SuspendedOrdersItem';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import IconCheckCircleAlt from '@cimpress-technology/react-streamline-icons/lib/IconCheckCircleAlt';
import { getProductVersionAndManagerUrlByProductVersion } from '../../helpers/productVersions';
import { get } from 'lodash';

const { warning, success, danger, white } = colors;

const renderCause = (cause: Cause, i: number) => (
  <div className="cause" key={i}>
    <span style={{ paddingLeft: '12px' }}>{cause.detail}</span>
  </div>
);

const routingOnlyMessage = 'This product configuration is for routing only and should not be fulfilled.';

const isRoutingOnlyOption = (option: Option) => {
  const causes = option?.problem?.causes;
  return (
    option?.problem?.detail === routingOnlyMessage ||
    !!causes?.find((cause) => cause?.detail?.includes(routingOnlyMessage))
  );
};

const renderDetail = (option: Option) => <div className="detail"> {option?.problem?.detail} </div>;
const renderCauses = (option: Option) =>
  option?.problem?.causes?.length === 1 ? (
    <div className="detail"> {option?.problem?.causes[0].detail} </div>
  ) : (
    option?.problem?.causes?.map(renderCause)
  );

const renderError = (option: Option) => {
  const causes = option?.problem?.causes;

  return causes && causes.length > 0 ? renderCauses(option) : renderDetail(option);
};

export const ProductConfigLinkWrapper = ({
  productConfigurationUrl,
  children,
}: {
  productConfigurationUrl: string | undefined;
  children: JSX.Element;
}): JSX.Element => {
  if (!productConfigurationUrl) {
    return children;
  }

  return (
    // don't close accordion on link click
    <a href={productConfigurationUrl} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
      {children}
    </a>
  );
};

const renderIdentifier = (option: Option, isSelectedOption: boolean, productManagerUrl: string | undefined) => {
  let referenceId = (
    <span className="mcpSku">
      <ProductConfigLinkWrapper productConfigurationUrl={productManagerUrl}>
        <span>{option.referenceId}</span>
      </ProductConfigLinkWrapper>
    </span>
  );
  if (isSelectedOption) {
    referenceId = (
      <span className="mcpSku">
        <IconCheckCircleAlt color={success.base} weight="fill" />
        <span> </span>
        <ProductConfigLinkWrapper productConfigurationUrl={productManagerUrl}>
          <span>{option.referenceId}</span>
        </ProductConfigLinkWrapper>
      </span>
    );
  } else if (option.problem?.detail || option.problem?.causes) {
    referenceId = (
      <span className="mcpSku">
        <IconAlertTriangle color={danger.base} weight="fill" />
        <span> </span>
        <ProductConfigLinkWrapper productConfigurationUrl={productManagerUrl}>
          <span>{option.referenceId}</span>
        </ProductConfigLinkWrapper>
      </span>
    );
  }

  return <div>{option._links?.productConfiguration?.href ? <div>{referenceId}</div> : <div> {referenceId} </div>}</div>;
};

const renderCost = (isLoadingCostData: boolean, costLoadingErrorMessage: string | null, costData: Costs | null) => {
  if (!costData?.totalCost?.USD && costData?.totalCost?.USD !== 0) {
    return null;
  }

  if (isLoadingCostData) {
    return (
      <div className="spinner">
        <span>
          <i>Retrieving Cost</i>
        </span>
        <span style={{ marginLeft: '4px' }}>
          <Spinner size="small" />
        </span>
      </div>
    );
  }

  if (costLoadingErrorMessage) {
    return <div className="error">{renderCostErrorMessage(costLoadingErrorMessage)}</div>;
  }

  const costDataErrorReason = costData?.errorReason;
  if (costDataErrorReason) {
    return <div className="error">{renderCostErrorMessage(costDataErrorReason)}</div>;
  }

  const costDataTotalCostUSD = costData?.totalCost?.USD;
  if (!costDataTotalCostUSD) {
    return (
      <div className="error">
        {renderCostErrorMessage('Error retrieving cost: TotalCost USD is missing from response')}
      </div>
    );
  }
  return <div>{`${Math.round(costDataTotalCostUSD * 100) / 100} USD`}</div>;
};

const renderCostErrorMessage = (message: string) => (
  <div>
    <Tooltip direction="left" contents={message} tooltipInnerStyle={{ maxWidth: '900px', textAlign: 'left' }}>
      <div className="ij-icon" style={{ verticalAlign: 'sub' }}>
        <span>COST ERROR</span>
        <span> </span>
        <IconAlertTriangle color={warning.base} weight="fill" />
      </div>
    </Tooltip>
  </div>
);

const renderFulfillmentConfiguration = (
  isLoadingFcUiLink: boolean,
  fulfillmentConfig: string | null,
  noFulfillmentConfigMsg: string,
) => {
  if (isLoadingFcUiLink) {
    return (
      <div style={{ display: 'flex' }}>
        <i style={{ marginRight: '1%' }}>Retrieving Fulfillment Configuration</i>
        <Spinner size="small" />
      </div>
    );
  }

  if (!fulfillmentConfig) {
    return noFulfillmentConfigMsg;
  }

  return (
    <div className="fulfillment-config">
      {fulfillmentConfig === noFulfillmentConfigMsg ? (
        fulfillmentConfig
      ) : (
        <a href={fulfillmentConfig || undefined} target="_blank" rel="noreferrer">
          {'Fulfillment Configuration'}
        </a>
      )}
    </div>
  );
};

export type SuspendedOrdersItemOptionProps = {
  environment: Environment;
  accessToken: string;
  option: Option;
  chosenOptionId: string | null;
  item: Item;
  selectPickMe: OptionSelectionHandler | undefined;
  existingPick: ExistingPick | null;
  isOptionEqualTo: OptionEqualityHandler;
};

const SuspendedOrdersItemOption: React.FC<SuspendedOrdersItemOptionProps> = ({
  environment,
  accessToken,
  option,
  chosenOptionId,
  item,
  selectPickMe,
  existingPick,
  isOptionEqualTo,
}) => {
  const [costData, setCostData] = useState<Costs | null>(null);
  const [isLoadingCostData, setIsLoadingCostData] = useState<boolean>(false);
  const [costLoadingErrorMessage, setCostLoadingErrorMessage] = useState<string | null>(null);
  const [isLoadingFcUiLink, setIsLoadingFcUiLink] = useState<boolean>(false);
  const [fcUiLink, setFcUiLink] = useState<string | null>(null);
  const [productManagerUrl, setProductManagerUrl] = useState<string | undefined>();
  const [productVersion, setProductVersion] = useState<string | undefined>();

  useEffect(() => {
    async function fetchData() {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (option._links.fulfillmentConfiguration?.href) {
          setIsLoadingFcUiLink(true);
          const fcUiLink = await getFcUiLink(option._links.fulfillmentConfiguration.href, accessToken);
          setFcUiLink(fcUiLink);
          setIsLoadingFcUiLink(false);

          if (option._embedded?.deliveryFeasibility?.price?.combined?.total) {
            const costs = {
              totalCost: {
                USD: option._embedded?.deliveryFeasibility?.price?.combined?.total,
              },
            };
            setCostData(costs);
          } else {
            setCostLoadingErrorMessage('Could not get total costs due to shipping or production costs missing');
          }
        }
      } catch (err: any) {
        setCostLoadingErrorMessage(err.message);
      } finally {
        setIsLoadingCostData(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function getProductData() {
      if (get(option, ['_links', 'productConfiguration'])) {
        const productDetails = await getProductVersionAndManagerUrlByProductVersion(
          option._links.productConfiguration?.href,
        );
        if (!productDetails) {
          return;
        }
        setProductManagerUrl(productDetails.productManagerUrl);
        setProductVersion(productDetails.productVersion);
      }
    }

    getProductData();
  }, [option]);

  let picked = false;

  if (existingPick) {
    picked = isOptionEqualTo(option, existingPick);
  }

  const isRoutingOnly = isRoutingOnlyOption(option);
  const className = isRoutingOnly
    ? 'suspended-orders-item route-only-option'
    : picked
    ? 'suspended-orders-item selected-option'
    : 'suspended-orders-item option';
  const isSelectedOption = Boolean(chosenOptionId && chosenOptionId === option.optionId);
  const noFulfillmentConfigMsg = 'No Fulfillment Configuration Available';
  const noProductConfigurationUrlMsg = 'No Product Configuration Available';

  let fulfillmentConfig: string | null = noFulfillmentConfigMsg;
  let productConfigurationUrl = noProductConfigurationUrlMsg;

  fulfillmentConfig = fcUiLink;
  productConfigurationUrl = option._links?.productConfiguration?.href || noProductConfigurationUrlMsg;

  const pickMeBtn = selectPickMe ? (
    <Button
      className="btn btn-default btn-sm"
      onClick={() => {
        if (productConfigurationUrl) {
          if (picked) {
            selectPickMe(null);
          } else {
            selectPickMe(option);
          }
        }
      }}
      disabled={!productConfigurationUrl}
    >
      {picked ? 'Picked!' : 'Pick Me!'}
    </Button>
  ) : null;

  const accordionStyle: React.CSSProperties = {
    position: 'relative',
    border: `0px solid ${white}`,
    padding: '0',
    margin: '0',
    backgroundColor: '#E1E6EB',
  };

  const bodyStyle: React.CSSProperties = {
    padding: '5px 5px 5px 30px',
    backgroundColor: '#E1E6EB',
  };

  const headerStyle: React.CSSProperties = {
    backgroundColor: '#E1E6EB',
    position: 'relative',
  };

  const accordianTitle = (
    <div style={{ height: '10px', display: 'flex', alignItems: 'center' }}>
      <IconAlertTriangle color={danger.base} weight="fill" />
      <span style={{ paddingLeft: '2px' }}>Causes</span>
    </div>
  );

  const costDisplay = renderCost(isLoadingCostData, costLoadingErrorMessage, costData);
  const isSuspendedItem = isSuspendedInRouting(item);

  return (
    <div className={className}>
      <div className="option-grid">
        <div className="identifier">
          <div className="mcpsku">{renderIdentifier(option, isSelectedOption, productManagerUrl)}</div>
          {option.productName && (
            <div className="name">
              <strong>Product Name:</strong> {option.productName}
            </div>
          )}
          <div className="fulfiller-name">
            <strong>Fulfiller:</strong> {option.fulfillerName || option.fulfillerId}
          </div>
        </div>
        {(costDisplay || isSuspendedItem) && (
          <div className="cost-selection-wrapper">
            {costDisplay && <div className="cost">{costDisplay}</div>}
            {isSuspendedItem && <div className="pickme">{pickMeBtn}</div>}
          </div>
        )}
      </div>
      <div className="product-config">
        {productConfigurationUrl === noProductConfigurationUrlMsg ? (
          productConfigurationUrl
        ) : (
          <>
            <a href={productConfigurationUrl} target="_blank" rel="noreferrer">
              {'Product Configuration'} {productVersion ? `(${productVersion})` : ''}
            </a>
          </>
        )}
      </div>
      {renderFulfillmentConfiguration(isLoadingFcUiLink, fulfillmentConfig, noFulfillmentConfigMsg)}
      {option.problem?.causes || option.problem?.detail ? (
        <Accordion
          title={accordianTitle}
          variant="default"
          style={{ ...accordionStyle, marginBottom: 0 }}
          headerStyle={headerStyle}
          bodyStyle={bodyStyle}
          defaultOpen={true}
        >
          {renderError(option)}
        </Accordion>
      ) : null}
      {option._links.shipmentPlan ? (
        <Accordion
          title="Shipment Plan"
          style={accordionStyle}
          headerStyle={headerStyle}
          bodyStyle={{ padding: '10px 10px 10px 35px', backgroundColor: '#ffffff' }}
          defaultOpen={true}
        >
          <ShipmentPlanViewer
            env={environment}
            shipmentPlanUrl={option._links.shipmentPlan[0].href}
            accessToken={accessToken}
            showBasicInfo={false}
          />
        </Accordion>
      ) : (
        <p>No shipment plan available</p>
      )}
    </div>
  );
};

export default SuspendedOrdersItemOption;
