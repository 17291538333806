import React, { CSSProperties, ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Dock } from 'react-dock';
import { css, cx } from '@emotion/css';
import { Close } from './internal';
import { granite } from './colors';
import { Direction } from './common';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';

export interface DrawerProps extends PublicComponentProps {
  /**
   * Whether to resize the drawer proportionally on window resize.
   */
  autoResize?: boolean;

  /**
   * Inline styles to be applied to the drawer body div.
   */
  bodyStyle?: CSSProperties;

  /**
   * Body of the drawer. By default, this area will be vertically scrollable if it doesn't fit on the page.
   */
  children?: ReactNode;

  /**
   * Controls whether or not the drawer should close when a click occurs outside of the component.
   */
  closeOnClickOutside?: boolean;

  /**
   * Footer for the drawer. Any buttons with actions should go here. If no footer is provided, the drawer will not render with a footer section.
   */
  footer?: ReactNode;

  /**
   * Inline styles to be applied to the drawer footer div.
   */
  footerStyle?: CSSProperties;

  /**
   * Header for the drawer. If no header is provided, the drawer will not render with a header section.
   */
  header?: ReactNode;

  /**
   * Inline styles to be applied to the drawer header div.
   */
  headerStyle?: CSSProperties;

  /**
   * Function to be called when the drawer wants to close.
   */
  onRequestHide?: () => void;

  /**
   * The side of the screen on which to display the drawer.
   */
  position?: Direction;

  /**
   * Controls whether or not the drawer is displayed.
   */
  show: boolean;

  /**
   * Width/height of drawer depending on drawer position i.e. [left, right] or [top, bottom] .
   * If `autoResize` is true, `size` is a fraction of window width/height; otherwise, `size` is in pixels.
   */
  size?: number;

  /**
   * z-index value to be be used for Drawer wrapper.
   */
  zIndex?: number;
}

const drawerHeaderCss = css({
  padding: cvar('spacing-24'),
  borderBottom: 'none',
  borderTopLeftRadius: cvar('spacing-4'),
  borderTopRightRadius: cvar('spacing-4'),

  h2: {
    marginTop: '18px',
    marginBottom: '9px',
  },
});

const drawerCrossBtn = css({
  color: cvar('color-icon-secondary'),
  fontSize: '30px',
  fontWeight: 100,
  lineHeight: '18px',
  textShadow: 'none',
  opacity: 1,
  cursor: 'pointer',
  marginTop: 0,
  padding: 0,
  backgroundColor: 'transparent',
  border: 0,
  float: 'right',
  '&:hover, &:active': {
    color: cvar('color-icon-secondary-active'),
    opacity: 1,
  },
});

const drawerContentCss = css({
  border: 0,
  boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
});

const drawerFooterCss = css({
  textAlign: 'right',
  padding: cvar('spacing-24'),
  borderTop: 'none',
});

const drawerBodyCss = css({
  position: 'static',
  padding: cvar('spacing-24'),
  overflowY: 'auto',
  flexGrow: 1,
});

export const Drawer = ({
  autoResize = true,
  bodyStyle,
  children,
  className,
  closeOnClickOutside,
  footer,
  footerStyle,
  header,
  headerStyle,
  onRequestHide,
  position = 'right',
  show,
  size = 0.3,
  style,
  zIndex = 1040,
  ...rest
}: DrawerProps) => {
  useEffect(() => {
    const drawerOpen = css`
      overflow: hidden;
    `;
    if (show) document.body.className = cx(document.body.className, drawerOpen);
    return () => {
      document.body.className = document.body.className.replace(`${drawerOpen}`, '');
    };
  }, [show]);

  const hideDrawer = () => {
    onRequestHide && onRequestHide();
  };

  // this is only called when clicking outside the drawer
  const handleVisibleChange = () => {
    if (closeOnClickOutside) {
      hideDrawer();
    }
  };

  let customHeader;
  if (header) {
    customHeader = React.isValidElement(header) ? header : <h2>{header}</h2>;
  }

  const drawerHeader = (
    <div className={drawerHeaderCss} style={headerStyle}>
      <button className={drawerCrossBtn} onClick={hideDrawer}>
        <Close width="18px" color={granite.base} cropped />
      </button>
      {customHeader}
    </div>
  );

  const drawerFooter = footer && (
    <div className={drawerFooterCss} style={footerStyle}>
      {footer}
    </div>
  );

  // don't allow any other values
  const drawerPosition = position || 'right';

  const drawerNode = (
    <Dock
      fluid={autoResize}
      size={size}
      position={drawerPosition}
      isVisible={show}
      dimMode="opaque"
      zIndex={zIndex}
      dimStyle={{ background: 'rgba(0, 0, 0, 0.5)' }}
      onVisibleChange={handleVisibleChange}
      {...rest}
    >
      <div className={cx('crc-drawer', drawerContentCss, className)} style={style}>
        {drawerHeader}
        <div className={drawerBodyCss} style={bodyStyle}>
          {children}
        </div>
        {drawerFooter}
      </div>
    </Dock>
  );

  return ReactDOM.createPortal(drawerNode, document.body);
};
