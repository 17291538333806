import React from 'react';
import { Cause, FailedDecision, SuccessfulDecision } from '../../types';
import './SuspendedOrdersDecisionDetail.css';

const renderDetail = (detail: string, title?: string) => (
  <div className="cause">
    {title ? `${title}: ` : ''}
    {detail}
  </div>
);

const renderCause = (cause: Cause, index: number) => <div key={index}>{renderDetail(cause.detail)}</div>;

const isFailedDecision = (decision: SuccessfulDecision | FailedDecision): decision is FailedDecision => {
  return (decision as SuccessfulDecision).chosenOptionId === undefined;
};

const SuspendedOrdersDecisionDetail: React.FC<{ decision: SuccessfulDecision | FailedDecision }> = ({ decision }) => {
  if (!isFailedDecision(decision)) {
    return null;
  }

  const problem = decision?.problem;

  return !problem ? null : (
    <div className="suspended-orders-decision-problem">
      {!problem.causes?.length ? renderDetail(problem.detail, problem.title) : problem.causes.map(renderCause)}
    </div>
  );
};

export default SuspendedOrdersDecisionDetail;
