import React from 'react';
import { white } from '../colors';

/* eslint-disable max-len */

interface CopySvgProps {
  color?: string;
  size?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const CopySvg = ({ color = white, size = '16px' }: CopySvgProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    color={color}
  >
    <path d="M21.7 4.7h-5.9c-.2.2-.6.6-1 1.1v-2c0-1.3-1-2.3-2.3-2.3H6.4C5.3 2.6 1 7.4 0 8.6V17c0 1.3 1 2.3 2.3 2.3h7v.9c0 1.3 1 2.3 2.3 2.3h10.1c1.3 0 2.3-1 2.3-2.3V7c0-1.3-1-2.3-2.3-2.3zM16.1 7v3.3c0 .6-.5 1.1-1.1 1.1h-3.1c-.2 0-.3-.2-.2-.3l4-4.2c.1-.2.4-.1.4.1zm-13.7.9l4-4.2c.1-.1.4 0 .4.1v3.3c0 .6-.5 1.1-1.1 1.1H2.6c-.2 0-.3-.2-.2-.3zm-.1 9.9c-.4 0-.8-.3-.8-.8V9.7c0-.1.1-.2.2-.2h4c1.3 0 2.4-1.1 2.4-2.4V3.2c0-.1.1-.2.2-.2h4.1c.4 0 .8.3.8.8v3.6c-1.5 1.7-3.3 3.6-3.9 4.3v6h-7zm20.2 2.4c0 .4-.3.8-.8.8H11.6c-.4 0-.8-.3-.8-.8v-7.3c0-.1.1-.2.2-.2h4c1.3 0 2.4-1.1 2.4-2.4V6.4c0-.1.1-.2.2-.2h4.1c.4 0 .8.3.8.8v13.2z" />
  </svg>
);
