import { Environment, LinkWithName, RoutingRequestResponse } from '../types';

const getBaseUrl = (environment: Environment): string => {
  if (environment === 'int') {
    return 'https://int-request-manager.lynx.cimpress.io';
  } else {
    return 'https://request-manager.lynx.cimpress.io';
  }
};

export async function retryRoutingRequest(
  environment: Environment,
  accessToken: string,
  routingRequestId: string,
  selections?: { itemLink: LinkWithName; chosenProductUrl: string; chosenFCUrl: string }[],
): Promise<void> {
  const url = new URL(getBaseUrl(environment));
  url.pathname = `/v1/routingRequests/${routingRequestId}:retry`;

  const body =
    selections && selections.length
      ? JSON.stringify({
          _embedded: {
            itemRoutingRequest: selections.map((r) => ({
              _links: {
                item: r.itemLink,
                productConfiguration: { href: r.chosenProductUrl },
                fulfillmentConfiguration: { href: r.chosenFCUrl },
              },
            })),
          },
        })
      : undefined;
  const res = await fetch(url.toString(), {
    headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' },
    method: 'POST',
    body,
  });

  if (!res.ok) {
    if (res.status === 422) {
      const data = await res.json();
      throw new Error(data.message);
    }
    throw new Error(
      `Error when attempting to retry the routing request ${routingRequestId}. Received status code ${res.status}.`,
    );
  }
}

export async function getRoutingRequest(routingRequestUrl: string): Promise<RoutingRequestResponse | undefined> {
  const routingRequestBody = await fetch(routingRequestUrl);
  if (!routingRequestBody.ok) {
    return;
  }

  return await routingRequestBody.json();
}
