import * as React from 'react';
import { white } from '../colors';

/* eslint-disable max-len */
const WarningSvg = (props: { [otherOptions: string]: unknown }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="currentColor"
    color={white}
    {...props}
  >
    <path d="M23.759 20.784 13.874 1.177A2.106 2.106 0 0 0 12 0c-.787 0-1.509.453-1.874 1.177L.24 20.784a2.27 2.27 0 0 0 .065 2.157A2.1 2.1 0 0 0 2.115 24h19.769a2.1 2.1 0 0 0 1.81-1.06 2.27 2.27 0 0 0 .064-2.156zM10.942 8.067c0-.606.474-1.098 1.058-1.098.584 0 1.058.492 1.058 1.098v6.591c0 .607-.474 1.098-1.058 1.098-.584 0-1.058-.491-1.058-1.098v-6.59zm1.11 12.643h-.029c-.866-.003-1.576-.715-1.61-1.614a1.657 1.657 0 0 1 .427-1.174 1.535 1.535 0 0 1 1.105-.507h.03c.866.001 1.577.712 1.611 1.611a1.66 1.66 0 0 1-.425 1.178 1.53 1.53 0 0 1-1.108.506z" />
  </svg>
);

export default WarningSvg;
