import { Environment } from '../types';

export type FulfillerIdentity = {
  fulfillerId: string;
  internalFulfillerId: string;
  name: string;
};

const getBaseUrl = (environment: Environment): string => {
  if (environment === 'int') {
    return 'https://fulfilleridentity.trdlnk.cimpress.io';
  } else {
    return 'https://fulfilleridentity.trdlnk.cimpress.io';
  }
};

export async function getFulfillerIdentityByFulfillerId(
  environment: Environment,
  accessToken: string,
  fulfillerId: string,
): Promise<FulfillerIdentity | undefined> {
  const url = new URL(getBaseUrl(environment));
  url.pathname = `/v1/fulfillers/${fulfillerId}`;
  const res = await fetch(url.toString(), { headers: { Authorization: `Bearer ${accessToken}` }, method: 'GET' });

  if (!res.ok) {
    // If we can't access the fulfiller identity service,
    // gracefully return instead of throwing an error since we can default to showing
    // the fulfiller ID (instead of the name information we get from the Fulfiller Identity Service)
    return undefined;
  }

  return res.json();
}
