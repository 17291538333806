import React, { useState, ReactNode } from 'react';
import copy from 'copy-to-clipboard';
import { css, cx } from '@emotion/css';
import { Tooltip } from '../Tooltip';
import { Button } from '../Button';
import { CopySvg } from './CopySvg';
import cvar from '../theme/cvar';
import { PublicComponentProps } from '../types';

export interface CopyProps extends PublicComponentProps {
  /**
   * The string to copy to clipboard when button is clicked
   */
  value: string;

  /**
   * The variant of copy component user wants to use. Button, text or inline.
   */
  variant?: 'button' | 'text' | 'inline';

  /**
   * Ths string to show in a Snackbar after copying is successful.
   */
  successMessage?: string;

  /**
   * The inner contents of the button.
   */
  children?: ReactNode;

  /**
   * Handler for when component is clicked to be copied.
   */
  onClick?: (e: React.KeyboardEvent | React.MouseEvent) => any;

  /**
   * Text to display in tooltip when user covers over component.
   */
  hoverText?: string;

  /**
   * How long (in milliseconds) to show the success message
   */
  duration?: number;
}

const copyTextCss = css`
  cursor: pointer;
  background-color: ${cvar('color-button-secondary-hover')};
  color: ${cvar('color-text-interactive')};
  font-weight: 400;
  border: none;
  padding: 0;
  &:hover,
  &:active {
    background-color: ${cvar('color-button-secondary-hover')};
  }
`;

const svgWrapperCss = css`
  display: inline-flex;
  align-self: center;
  top: 0.125em;
  position: relative;
  padding-left: ${cvar('spacing-4')};
`;

const pointer = css`
  cursor: pointer;
`;

export const Copy = ({
  variant = 'text',
  children,
  value,
  successMessage = 'Copied successfully.',
  onClick,
  hoverText,
  duration = 1500,
  className,
  ...rest
}: CopyProps) => {
  const [copied, setCopied] = useState<boolean>(false);
  const textOnHover = hoverText || (variant !== 'button' ? 'Click To Copy' : '');

  const clickHandler = (e: React.MouseEvent) => {
    copy(value || '');
    setCopied(true);
    setTimeout(() => setCopied(false), duration);
    if (onClick) onClick(e);
  };

  const child =
    variant === 'button' ? (
      children || 'Copy'
    ) : (
      <>
        {children || value}
        <div className={svgWrapperCss}>
          <CopySvg color={cvar('color-text-interactive')} size={'1em'} aria-hidden />
        </div>
      </>
    );

  const button = (
    <Button
      className={cx({ [copyTextCss]: variant !== 'button' }, pointer, className)}
      onClick={clickHandler}
      {...rest}
      aria-label={`${children || value}. ${textOnHover}`}
    >
      {child}
    </Button>
  );

  if (variant === 'button' && !copied) {
    return button;
  }

  return (
    <Tooltip direction="top" contents={copied ? successMessage : textOnHover}>
      {button}
    </Tooltip>
  );
};
