import { Environment, Item } from '../types';

const getBaseUrl = (environment: Environment): string => {
  if (environment === 'int') {
    return 'https://int-item-service.commerce.cimpress.io';
  } else {
    return 'https://item-service.commerce.cimpress.io';
  }
};

export async function getItemByItemId(itemId: string, accessToken: string, environment: Environment): Promise<Item> {
  const url = `${getBaseUrl(environment)}/v1/items/${itemId}`;

  const res = await fetch(url, { headers: { Authorization: `Bearer ${accessToken}` }, method: 'GET' });

  if (!res.ok) {
    throw new Error(`Error retrieving item. Received status code ${res.status}.`);
  }

  return res.json();
}
