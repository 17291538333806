import { Environment } from '../types';

export type McpProduct = {
  referenceId: string;
  fulfillerId: string;
  name: string;
};

const getBaseUrl = (environment: Environment): string => {
  if (environment === 'int') {
    return 'https://introduction.products.cimpress.io';
  } else {
    return 'https://introduction.products.cimpress.io';
  }
};

export async function getMcpProductByMcpSku(
  environment: Environment,
  accessToken: string,
  mcpSku: string,
): Promise<McpProduct> {
  const url = new URL(getBaseUrl(environment));
  url.pathname = `/v1/products/${mcpSku}`;
  const res = await fetch(url.toString(), { headers: { Authorization: `Bearer ${accessToken}` }, method: 'GET' });

  if (!res.ok) {
    throw new Error(`Error retrieving mcpSku ${mcpSku}. Received status code ${res.status}.`);
  }

  return res.json();
}
