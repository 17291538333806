import * as React from 'react';
import SuspendedOrdersDecisionDetail from './SuspendedOrdersDecisionDetail';
import SuspendedOrdersItemOptions from './SuspendedOrdersItemOptions';
import {
  FailedDecision,
  ExistingPick,
  Item,
  Option,
  Environment,
  SuccessfulDecision,
  InvalidConsideredOption,
  ValidConsideredOption,
  RoutingConfigurationDetails,
  LinkWithName,
} from '../../types';
import { Accordion, Alert, Button, Modal } from '@cimpress/react-components';
import { useCallback, useState } from 'react';
import { retryRoutingRequest } from '../../apis/request-manager';
import { getMcpProductByMcpSku } from '../../apis/product-introduction';
import { Spinner, colors } from '@cimpress/react-components';
import SuspendedOrdersItemHeaderInfo from './SuspendedOrdersItemHeaderInfo';
import { get, uniqWith } from 'lodash';
import IconCheckCircleAlt from '@cimpress-technology/react-streamline-icons/lib/IconCheckCircleAlt';
import './SuspendedOrdersItem.css';
import { isOptionEqualTo } from '../../helpers/IsOptionEqualTo';
import { getFulfillmentConfigName } from '../../helpers/optionGetters';
import { RoutingDetails } from '../shared/RoutingDetails';
import { getRoutingConfig } from '../../helpers/routingConfig';

const { silver, success } = colors;

export type SuspendedOrdersItemProps = {
  accessToken: string;
  userProfile: any;
  environment: Environment;
  item: Item;
  putFailedDecisionsFirst?: (itemId: string, hasDecisionProblem: boolean) => void;
  setItemsTableLabel?: (label: string) => void;
  isModal?: boolean; // isShowingModal and closeModal required if this is true
  isShowingModal?: boolean;
  closeModal?: () => void;
  defaultOpen?: boolean;
};

export type OptionSelectionHandler = (option: Option | null) => void;

export const isSuspendedInRouting = (item: Item): boolean =>
  Boolean(
    item.statuses?.routing?.state === 'current' &&
      (item.statuses?.suspended?.state === 'current' || item.statuses?.onHold?.state === 'current'),
  );

const SuspendedOrdersItem: React.FC<SuspendedOrdersItemProps> = (props) => {
  const { accessToken, userProfile, environment, item, isModal, isShowingModal, closeModal, defaultOpen } = props;
  let { putFailedDecisionsFirst, setItemsTableLabel } = props;

  putFailedDecisionsFirst = putFailedDecisionsFirst
    ? putFailedDecisionsFirst
    : () => {
        return;
      };

  setItemsTableLabel = setItemsTableLabel
    ? setItemsTableLabel
    : () => {
        return;
      };

  const [isSendingRemediation, setIsSendingRemediation] = useState<boolean | null>(null);
  const [message, setMessage] = useState<string>('');
  const [messageType, setMessageType] = useState<'error' | 'info' | 'success' | ''>('');
  const [decision, setDecision] = useState<SuccessfulDecision | FailedDecision | null>(null);
  const [initiatedDecisionLink, setInitiatedDecisionLink] = useState<string | null>(null);
  const [currentDecisionLink, setCurrentDecisionLink] = useState<string | null>(null);
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<ExistingPick | null>(null);
  const [attemptedDecisionFetch, setAttemptedDecisionFetch] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [buyer, setBuyer] = useState<string | null>(null);
  const [configuration, setConfiguration] = useState<RoutingConfigurationDetails>(undefined);
  const [chosenOptionId, setChosenOptionId] = useState<string | null>(null);
  const [chosenFulfiller, setChosenFulfiller] = useState<string | null>(null);

  const onOptionSelection: OptionSelectionHandler = (option: Option | null) => {
    // why is this only for invalid?
    if (option === null || !option._links?.productConfiguration?.href || !option._links?.fulfillmentConfiguration) {
      setSelectedOption(null);
    } else {
      setSelectedOption({
        productConfigUrl: option._links.productConfiguration.href,
        itemId: item.itemId,
        fulfillerId: option.fulfillerId,
        orderId: item.orderId,
        referenceId: option.referenceId,
        fulfillmentConfiguration: option._links.fulfillmentConfiguration.href,
        itemLink: item._links.self as LinkWithName,
      });
    }
  };

  const sendRemediation = useCallback(async () => {
    const successMessage = selectedOption ? 'Remediation sent successfully.' : 'Routing request retried successfully.';

    if (isSendingRemediation) {
      return;
    }

    setIsSendingRemediation(true);

    try {
      if (selectedOption) {
        await retryRoutingRequest(environment, accessToken, item._links?.routingRequest?.name || '', [
          {
            itemLink: selectedOption.itemLink,
            chosenFCUrl: selectedOption.fulfillmentConfiguration,
            chosenProductUrl: selectedOption.productConfigUrl,
          },
        ]);
      } else {
        await retryRoutingRequest(environment, accessToken, item._links?.routingRequest?.name || '');
      }

      setMessageType('success');
      setMessage(successMessage);
    } catch (err: any) {
      setMessageType('error');
      setMessage(err.message);
    } finally {
      setIsSendingRemediation(false);
    }
  }, [isSendingRemediation, selectedOption]);

  const fetchDecisionData = () => {
    async function fetchDecision() {
      try {
        let retrievedDecision;
        let currentDecisionLink = undefined;
        let initialDecisionLink = undefined;

        if (get(item, ['_links', 'routingRequest'])) {
          const itemRoutingRequestResponse = await fetch(item._links.routingRequest.href, {
            headers: { Authorization: `Bearer ${accessToken}` },
            method: 'GET',
          });
          if (itemRoutingRequestResponse.ok) {
            const retrievedItemRoutingRequest = await itemRoutingRequestResponse.json();
            setBuyer(retrievedItemRoutingRequest.buyer);

            const itemRoutingResultList = retrievedItemRoutingRequest._embedded.routingResult;

            setIsSuccessful(retrievedItemRoutingRequest.requestStatus === 'done'); //  does done actually mean successful?

            const itemRoutingDecision =
              itemRoutingResultList?.find((result: { _links: { item: { name: string } } }) => {
                return result._links.item.name === item.itemId;
              }) ||
              (itemRoutingResultList?.length > 0 && itemRoutingResultList[0]);

            if (itemRoutingDecision && itemRoutingDecision._links.routingDecision) {
              currentDecisionLink = itemRoutingDecision._links.routingDecision.href;
              setCurrentDecisionLink(currentDecisionLink);
              const routingDecisionResponse = await fetch(itemRoutingDecision._links.routingDecision.href, {
                headers: { Authorization: `Bearer ${accessToken}` },
                method: 'GET',
              });
              if (routingDecisionResponse.ok) {
                retrievedDecision = await routingDecisionResponse.json();
              }
            }
            const itemRoutingRequestList = retrievedItemRoutingRequest._embedded.itemRoutingRequest;
            const itemInitiatedDecision = itemRoutingRequestList?.find(
              (result: { _links: { item: { name: string } } }) => result._links.item.name === item.itemId,
            );
            if (itemInitiatedDecision && itemInitiatedDecision._links.initiatedDecision) {
              initialDecisionLink = itemInitiatedDecision._links.initiatedDecision.href;
              setInitiatedDecisionLink(initialDecisionLink);
            }
          }
        }

        if (!retrievedDecision) {
          setMessageType('info');
          setMessage('No routing decision was found for this item.');
          setChosenOptionId(null);
          setChosenFulfiller(null);
          return;
        } else {
          setDecision(retrievedDecision);
          const chosenOptionId = retrievedDecision.chosenOptionId;
          setChosenOptionId(chosenOptionId);

          const routingConfigDetails = await getRoutingConfig(retrievedDecision, environment, accessToken, item);
          setConfiguration(routingConfigDetails);

          const consideredOptions = retrievedDecision.consideredOptions;

          let enrichedOptionsPromises = undefined;

          // set enrichedOptionsPromises
          if (consideredOptions) {
            enrichedOptionsPromises = consideredOptions?.map(async (consideredOption: Option): Promise<Option> => {
              const mcpProduct = await getMcpProductByMcpSku(environment, accessToken, consideredOption.referenceId);
              const fulfillerId = consideredOption.fulfillerId || mcpProduct.fulfillerId;
              // Some invalid options don't have a fulfiller ID, so we need to try to get one
              const fulfillerName = await getFulfillmentConfigName(environment, accessToken, {
                ...consideredOption,
                fulfillerId,
              });
              const fulfillmentConfiguration = 'no fulfillment configuration available';

              return {
                ...consideredOption,
                fulfillerId,
                productName: mcpProduct.name,
                fulfillerName,
                fulfillmentConfiguration,
              };
            });
          }

          if (enrichedOptionsPromises) {
            let enrichedOptions: Array<Option> | undefined = undefined; // consideredOptions
            let allEnrichedOptions: Array<Option> | undefined = undefined; // final list we actually give to SuspendedOrdersItemOptions

            // resolve enrichedOptionsPromises to actual options
            if (enrichedOptionsPromises) {
              enrichedOptions = await Promise.all(enrichedOptionsPromises);
            }
            allEnrichedOptions = enrichedOptions;

            if (allEnrichedOptions) {
              // remove duplicates (doesn't work yet)
              const allEnrichedOptionsUnique = uniqWith(allEnrichedOptions, isOptionEqualTo);

              setOptions(allEnrichedOptionsUnique);
            }

            const selectedOption = allEnrichedOptions?.find((option) => option.optionId === chosenOptionId);
            setChosenFulfiller(selectedOption?.fulfillerName || selectedOption?.fulfillerId || null);
          }
        }
        setAttemptedDecisionFetch(true);
        if (putFailedDecisionsFirst) {
          putFailedDecisionsFirst(item.itemId, (retrievedDecision as FailedDecision)?.problem !== undefined);
        }
      } catch (err: any) {
        setMessageType('error');
        setMessage(err.message);
      }
    }

    if (!decision) {
      fetchDecision();
    }
  };

  let submitButtonLabel = 'Retry';
  if (selectedOption && !isSuccessful) {
    submitButtonLabel = 'Submit';
    setItemsTableLabel('Submit all');
  } else if (!isSuccessful) {
    setItemsTableLabel('Retry all'); // this might cause extra re-renders for itemsTable
  }
  if (isSendingRemediation) {
    submitButtonLabel += '...';
  }

  let retryStatus = <div></div>;
  if (message) {
    let icon: React.ReactNode = null;
    let iconClassName = '';

    switch (messageType) {
      case 'error':
        icon = <Alert message={message} status="warning" dismissible={false} />;
        iconClassName = 'text-danger';
        break;
      case 'info':
        icon = <Alert message={message} status="info" dismissible={false} />;
        iconClassName = 'text-info';
        break;
      case 'success':
        icon = <Alert message={message} status="success" dismissible={false} />;
        iconClassName = 'text-success';
        break;
    }

    retryStatus = (
      <div className="alert-box">
        <span className={iconClassName}>{icon}</span>
      </div>
    );
  }

  const accordionHeader = (
    <div className="header-main">
      <div className="header-left">
        <SuspendedOrdersItemHeaderInfo
          item={item}
          decision={decision}
          attemptedDecisionFetch={attemptedDecisionFetch}
        />
      </div>
      {retryStatus}
      {isSuspendedInRouting(item) ? (
        <div className="header-right">
          <Button variant="default" disabled={!!isSendingRemediation} onClick={sendRemediation}>
            {submitButtonLabel}
          </Button>
        </div>
      ) : (
        <div className="header-right">
          {chosenFulfiller ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconCheckCircleAlt color={success.base} weight="fill" />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );

  const accordionStyle = {
    marginBottom: '10px',
    marginLeft: '0px',
    marginRight: '0px',
    border: `1px solid ${silver}`,
  };

  const headerStyle = {
    backgroundColor: 'white',
    position: 'relative',
  } as React.CSSProperties;

  const bodyStyle = {
    backgroundColor: 'white',
    padding: '0 16px 16px',
  };

  let accordionBody = null;

  if (isSuccessful && !selectedOption) {
    const chosenOption = options.find((option) => {
      const decisionSelectedOption = (decision as SuccessfulDecision)?.selectedOption;

      if (decisionSelectedOption) {
        return isOptionEqualTo(option, decisionSelectedOption);
      }
      return false;
    });

    if (chosenOption) {
      let chosenOptionToExistingPick = undefined;

      if (chosenOption?._links?.productConfiguration) {
        chosenOptionToExistingPick = {
          productConfigUrl: chosenOption._links.productConfiguration.href,
          itemId: item.itemId,
          fulfillerId: chosenOption.fulfillerId,
          orderId: item.orderId,
          referenceId: chosenOption.referenceId,
          fulfillmentConfiguration: chosenOption._links?.fulfillmentConfiguration?.href || '',
          itemLink: item._links.self as LinkWithName,
        };
      } else {
        chosenOptionToExistingPick = {
          productConfigUrl: (chosenOption as ValidConsideredOption | InvalidConsideredOption)?.productConfigurationUrl,
          itemId: item.itemId,
          orderId: item.orderId,
          fulfillerId: chosenOption.fulfillerId,
          referenceId: (chosenOption as ValidConsideredOption | InvalidConsideredOption).referenceId,
          fulfillmentConfiguration: chosenOption._links.fulfillmentConfiguration?.href || '',
          itemLink: item._links.self as LinkWithName,
        };
      }

      setSelectedOption(chosenOptionToExistingPick);
    }
  }

  if (decision && (options.length || attemptedDecisionFetch)) {
    accordionBody = (
      <div>
        <SuspendedOrdersDecisionDetail decision={decision} />
        <RoutingDetails
          item={item}
          buyer={buyer}
          configuration={configuration}
          initiatedDecisionLink={initiatedDecisionLink}
          currentDecisionLink={currentDecisionLink}
          userProfile={userProfile}
        />
        <h2>Available Options</h2>
        <SuspendedOrdersItemOptions
          accessToken={accessToken}
          environment={environment}
          options={options}
          item={item}
          existingPick={selectedOption}
          chosenOptionId={chosenOptionId}
          onOptionSelection={!isSuccessful ? onOptionSelection : undefined}
          isOptionEqualTo={isOptionEqualTo}
        />
      </div>
    );
  } else if (messageType == '') {
    defaultOpen ? fetchDecisionData() : null;
    accordionBody = (
      <div>
        <RoutingDetails
          item={item}
          buyer={buyer}
          configuration={configuration}
          initiatedDecisionLink={initiatedDecisionLink}
          currentDecisionLink={currentDecisionLink}
          userProfile={userProfile}
        />
        <h2>Available Options</h2>
        <br />
        <Spinner />
      </div>
    );
  } else {
    accordionBody = (
      <div>
        <RoutingDetails
          item={item}
          buyer={buyer}
          configuration={configuration}
          initiatedDecisionLink={initiatedDecisionLink}
          currentDecisionLink={currentDecisionLink}
          userProfile={userProfile}
        />
      </div>
    );
  }

  const embeddedComponent = (
    <div className="suspended-orders-item">
      <Accordion
        defaultOpen={defaultOpen}
        className="options-list"
        title={accordionHeader}
        variant="default"
        onHeaderClick={defaultOpen ? undefined : fetchDecisionData}
        style={accordionStyle}
        headerStyle={headerStyle}
        bodyStyle={bodyStyle}
      >
        {accordionBody}
      </Accordion>
    </div>
  );

  return isModal && isShowingModal !== undefined ? (
    <Modal
      className="decision-viewer-modal"
      style={{ width: '1000px' }}
      show={isShowingModal}
      onRequestHide={closeModal}
      closeOnOutsideClick={true}
    >
      {embeddedComponent}
      <Button variant="default" onClick={closeModal}>
        Close
      </Button>
    </Modal>
  ) : (
    embeddedComponent
  );
};

export default SuspendedOrdersItem;
