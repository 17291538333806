import { css, cx } from '@emotion/css';
import React from 'react';
import cvar from './theme/cvar';
import defaultTheme from './theme/theme';
import { PublicComponentProps } from './types';

type Status = 'danger' | 'default' | 'info' | 'primary' | 'success' | 'warning';
type Size = 'sm' | 'lg' | 'default';

export interface LabelProps extends PublicComponentProps {
  /**
   * The text shown on the label.
   */
  text: string;

  /**
   * The interaction status of the label.
   */
  status?: Status;

  /**
   * The size of the label.
   */
  size?: Size;
}

const backgroundColorMap: Record<Status, keyof typeof defaultTheme> = {
  success: 'color-background-success',
  info: 'color-background-info',
  warning: 'color-background-warning',
  danger: 'color-background-error',
  default: 'color-interactive',
  primary: 'color-interactive',
};

const paddingTopBottomMap: Record<Size, keyof typeof defaultTheme> = {
  sm: 'spacing-2',
  lg: 'spacing-4',
  default: 'spacing-4',
};
const paddingTLeftRightMap: Record<Size, keyof typeof defaultTheme> = {
  sm: 'spacing-8',
  lg: 'spacing-16',
  default: 'spacing-12',
};
const fontMap: Record<Size, keyof typeof defaultTheme> = {
  sm: 'text-label-small',
  lg: 'text-label-large',
  default: 'text-label-default',
};

const borderRadiusMap: Record<Size, keyof typeof defaultTheme> = {
  sm: 'spacing-8',
  lg: 'spacing-16',
  default: 'spacing-12',
};

const labelCss = (status: Status, size: Size) => css`
  color: ${cvar('color-text-inverse')};
  padding: ${cvar(paddingTopBottomMap[size])} ${cvar(paddingTLeftRightMap[size])};
  font: ${cvar(fontMap[size])};
  background-color: ${cvar(backgroundColorMap[status])};
  display: inline-block;
  border-radius: ${cvar(borderRadiusMap[size])};
`;

export const Label = ({ className, text, status, size, ...props }: LabelProps) => (
  <span className={cx('crc-label', labelCss(status || 'default', size || 'default'), className)} {...props}>
    {text}
  </span>
);
