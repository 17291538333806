import React, { Component, CSSProperties } from 'react';
import { allCountries, Country } from 'country-telephone-data';
import { parseNumber, formatNumber, AsYouType, isValidNumber, CountryCode, ParsedNumber } from 'libphonenumber-js';
import { components } from 'react-select';
import { css, cx } from '@emotion/css';
import { Select } from './Select';
import { SelectWrapper } from './SelectWrapper';
import { TextField } from './TextInput';
import emojiFlags from './CurrencySelector/isoUnicode';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';

const isEmptyObject = (obj: Record<any, any>) => Object.entries(obj).length === 0;

export interface PhoneProps extends PublicComponentProps {
  disableStatus: any;

  /**
   * Optional set of country groups.
   * Every country group is represented by a member that has a name (that will be used as a label for this group)
   *   and a list of country codes to include (or * for all countries)
   */
  countryGroups?: { Frequent: string[]; All: string[] | string };

  /**
   * Overrides the label for the country code select dropdown.
   */
  countrySelectLabel?: string;

  /**
   * Additional CSS classes to apply to the country code select dropdown.
   */
  countrySelectClassName?: string;

  /**
   * Styles to be applied to the country select drop down menu.
   */
  countrySelectMenuStyle?: CSSProperties;

  /**
   * Set to true to prevent bootstrap styling on text inputs when phone number is invalid.
   */
  disableBsStatus?: boolean;

  /**
   * Overrides the label for the phone number text input.
   */
  extensionTextInputLabel?: string;

  /**
   * Additional CSS classes to apply to the extension text input.
   */
  extensionTextInputClassName?: string;

  /**
   * If included, the phone component will parse and prepopulate with the initial value on mount.
   */
  initialValue?: string;

  /**
   * Callback function that is fired when any part of the phone component changes.
   *
   * Note: Properly formatted numbers that do not exist will be considered invalid.
   */
  onChange: (value?: { number: string; isValid: boolean }) => void;

  /**
   * Overrides the label for the phone number text input.
   */
  phoneTextInputLabel?: string;

  /**
   * Additional CSS classes to apply to the phone number text input.
   */
  phoneTextInputClassName?: string;

  /**
   * Selector component to use for the country selection (i.e. react-select-plus).
   *
   * If not specified - react-select will be used.
   */
  selectedSelect?: any;

  /**
   * Treats mounting the component as a change event. Useful for when passing an initial value.
   */
  triggerChangeOnMount?: boolean;

  /**
   * Deprecated: Set to true to prevent validation styling on text inputs when phone number is invalid.
   */
  disableBsStyling?: boolean;
}

interface PhoneStates {
  countryCode: CountryCode;
  minimumPhoneNumber: ParsedNumber | string;
  phone: string;
  extension: string;
  isFocusPhoneNumber: boolean;
}

const emojiStyle = css`
  display: inline-block;
  width: 18px;

  > img {
    width: 100%;
    vertical-align: middle;
  }
`;

const overflowStyle = css({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const CustomPhoneSelect = (props: { value: string; label: string; type: string }) => {
  const { value, label, type } = props;
  const flag = emojiFlags[value.toUpperCase()] || '';

  const outerStyle = type === 'option' ? undefined : overflowStyle;
  return (
    <div className={outerStyle}>
      {/* eslint-disable react/no-danger  */}
      <span className={emojiStyle} dangerouslySetInnerHTML={{ __html: flag }} />
      {/* eslint-enable react/no-danger */}
      &nbsp; {`${label}`}
    </div>
  );
};

/**
 * Builds a select option (dropdown entry) for a given country
 * @param {*} country
 * @returns select option for the country
 */
const buildCountrySelectOptions = (country: Country) => {
  const { name, dialCode, iso2 } = country;

  let label = name;

  // Make USA and UK easier to find by typing
  if (iso2 === 'us') {
    label = `${label} (USA)`;
  } else if (iso2 === 'gb') {
    label = `${label} (UK)`;
  }

  if (dialCode) {
    label = `${label} (+${dialCode})`;
  }

  return {
    label,
    value: iso2,
  };
};

/**
 * Composes a structure that can be displayed in react-select
 * (or another selector that supports grouped options)
 * @param {*} allCountryOptionsList - select options for all the countries in one list
 * @param {*} allCountryOptionsHash - a lookup table of all the select options
 * @param {*} countryGroups - set of country code groups
 * @returns the group structure of select options to be displayed in the country selector
 */
const buildOptionGroupsResult = (
  allCountryOptionsList: { label: string; value: string }[],
  allCountryOptionsHash: { [x: string]: any },
  countryGroups: any,
) => {
  const resultCountryOptions: any = [];
  Object.keys(countryGroups).forEach(group => {
    if (countryGroups[group] === '*') {
      resultCountryOptions.push({
        label: group,
        options: allCountryOptionsList, // use 'all countries' for a group with '*' filter
      });
    } else if (Array.isArray(countryGroups[group])) {
      resultCountryOptions.push({
        label: group,
        options: countryGroups[group].map((countryCode: string) => allCountryOptionsHash[countryCode.toLowerCase()]),
      });
    }
  });
  return resultCountryOptions;
};

/**
 * Builds a list (or a grouped structure) of select options to display in the country selector
 * @param {*} countryGroups - optional set of country code groups
 *            Example:
 *            {
 *              'Frequent': ['us', 'gb'],
 *              'All': '*'
 *            }
 * @returns the list/group structure of select options to be displayed in the country selector
 */
export const buildCountryList = (countryGroups?: { Frequent: string[]; All: string[] | string }) => {
  const allCountryOptionsList: { label: string; value: string }[] = [];
  const allCountryOptionsHash: any = {};

  // Build the list of select options for all countries
  allCountries.forEach(country => {
    if (Boolean(country.dialCode) && Boolean(country.iso2)) {
      const countryOption = buildCountrySelectOptions(country);
      allCountryOptionsList.push(countryOption);
      allCountryOptionsHash[country.iso2] = countryOption;
    }
  });

  // Prepare and return the result
  if (!countryGroups) {
    return allCountryOptionsList;
  }
  return buildOptionGroupsResult(allCountryOptionsList, allCountryOptionsHash, countryGroups);
};

export const getCountryCallingCode = (countryCode: string): string => {
  const { dialCode } = allCountries.find(({ iso2 }) => iso2 === countryCode) || {};
  return dialCode ? `+${dialCode}` : '';
};

function parseInvalidPhoneNumber(invalidPhoneNumber = ''): any {
  // pick out phone and ext.
  const phoneParts: string[] = invalidPhoneNumber.split(/[A-Za-z]+/);
  let parsedPhone = {};

  if (phoneParts.length === 1) {
    // Option 1 - we only have digits
    parsedPhone = {
      phone: invalidPhoneNumber,
      ext: '',
    };
  } else if (phoneParts.length > 1) {
    // Option 2 - the phone number has both digits and alpha characters.
    // We can only do our best here. For now, assume the first segment of
    // digits is the phone number and the last segment of digits is an
    // extension.
    parsedPhone = {
      phone: phoneParts[0],
      ext: phoneParts[phoneParts.length - 1].replace(/\D/g, ''),
    };
  }

  return parsedPhone;
}

// Determines the number of digits before the caret/cursor
function getDigitsBeforeCaret(caretLocation: number, phone: string) {
  const characters = phone.split('');
  let digitsBeforeCaret = 0;
  for (let i = 0; i < caretLocation; i++) {
    if (characters[i].match(/\d|\+/)) {
      digitsBeforeCaret++;
    }
  }
  return digitsBeforeCaret;
}

/* eslint-disable consistent-return */
function findMatchingCountrySelectOption(
  countrySelectOptions: string | any[],
  countryCode: CountryCode,
  index = 0,
): any {
  if (index >= countrySelectOptions.length) {
    return;
  }

  const option = countrySelectOptions[index];

  // Check whether this is a plain option or an option describing a sub group of options
  if (option.value) {
    return option.value === countryCode
      ? option
      : findMatchingCountrySelectOption(countrySelectOptions, countryCode, index + 1);
  }

  if (option.options) {
    return (
      findMatchingCountrySelectOption(option.options, countryCode) ||
      findMatchingCountrySelectOption(countrySelectOptions, countryCode, index + 1)
    );
  }
}
/* eslint-enable consistent-return */

const defaultCountryGroups = buildCountryList();

const phoneCss = css({
  display: 'flex',
});

const phoneCountryCss = css({
  marginRight: cvar('spacing-4'),
  minWidth: '175px',
});

const phoneNumberCss = css({
  marginRight: cvar('spacing-4'),
  flexGrow: 2,
  minWidth: '275px',
});

const phoneExtensionCss = css({
  width: '15%',
  minWidth: '70px',
});

export class Phone extends Component<PhoneProps, PhoneStates> {
  caretCorrection: any;

  wasDeletePressed: boolean = false;

  phoneNumberRef: React.RefObject<HTMLInputElement>;

  constructor(props: Readonly<PhoneProps>) {
    super(props);

    // Parse any initialValue we receive.
    const { country = '', phone = '', ext = '' } = this.parseInitialPhoneNumber(this.props.initialValue!);

    this.state = {
      countryCode: country.toLowerCase(),
      minimumPhoneNumber: getCountryCallingCode(country.toLowerCase()) || '',
      phone: formatNumber(phone, country, 'INTERNATIONAL'),
      extension: ext,
      isFocusPhoneNumber: false,
    };

    this.phoneNumberRef = React.createRef(); // this ref is used to focus on phone number field
    this.onChangeCountryCode = this.onChangeCountryCode.bind(this);
    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
    this.onExtensionChange = this.onExtensionChange.bind(this);
    this.onPhoneNumberKeyPress = this.onPhoneNumberKeyPress.bind(this);
  }

  // If the component is fed an intial value, the parent component may want to
  // treat the initial parsing of the phone number as a change event so it can
  // receive a properly formatted version of the number immediately.
  componentDidMount() {
    const { phone, extension } = this.state;
    this.props.triggerChangeOnMount && this.onChange(phone, extension);
  }

  // NOTE: This is not a 'controlled component' in React's eyes. It is up to us
  // to ensure that the cursor does not reset to the end of the phone text field
  // after each time we predictively format the number.
  componentDidUpdate(prevProps) {
    this.caretCorrection && this.caretCorrection();
    delete this.caretCorrection;
    // When the passed in initial value is updated the state value should also be updated in order to render
    // proper value in the component
    if (this.props.initialValue !== prevProps.initialValue) {
      const { country = '', phone = '', ext = '' } = this.parseInitialPhoneNumber(this.props.initialValue!);
      this.setState({
        // update countryCode only if the above returns a valid value
        ...(country && { countryCode: country.toLowerCase() }),
        phone: formatNumber(phone, country, 'INTERNATIONAL'),
        extension: ext,
      });
    }
    // Focus on the phone number field after country code has changed
    // Doing it here takes care of the situations where phone number field is disabled initially
    if (this.state.isFocusPhoneNumber) {
      this.phoneNumberRef.current && this.phoneNumberRef.current.focus();
      this.setState({ isFocusPhoneNumber: false }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  onChangeCountryCode(event: any) {
    const countryCode = event?.value;
    if (!countryCode) {
      // Clearing the country code is equivalent to clearing the entire
      // component of its value. You can't have a telephone number without the
      // country code.
      this.setState({
        countryCode: '' as CountryCode,
        phone: '',
        extension: '',
      });
      this.props.onChange(undefined);
    } else {
      const countryCallingCode = getCountryCallingCode(countryCode);
      this.setState({
        countryCode,
        minimumPhoneNumber: countryCallingCode,
        phone: new AsYouType().input(countryCallingCode),
        extension: '',
        isFocusPhoneNumber: true, // set the focus on the phone number field
      });
      this.props.onChange({ number: countryCallingCode, isValid: false });
    }
  }

  // Remember whether this key press was delete (i.e. remove characters from
  // from left to right). This will prove to be useful when determining the new
  // position of the cursor/caret after the change has been processed.
  onPhoneNumberKeyPress(event: { key: string }) {
    this.wasDeletePressed = event.key === 'Delete';
  }

  // Callback for when the phone input field changes. Because the phone number
  // value is computed based on what the user enters, React will overwrite the
  // value each time and place the input caret/cursor at the end. This callback
  // manually resets the caret/cursor back to where the user previously had it.
  onPhoneNumberChange(event: { target: any }) {
    let phone = event?.target?.value ?? '';
    const { minimumPhoneNumber } = this.state;
    let newCaretLocation: number;

    // If the number doesn't start with the proper country code or if the
    // formatter results in empty string, reset the number because our user
    // is being silly.
    const shouldResetNumber =
      !phone.replace(/\s+/g, '').startsWith(minimumPhoneNumber) || !new AsYouType().input(phone);
    if (shouldResetNumber) {
      phone = new AsYouType().input(minimumPhoneNumber as string);
      newCaretLocation = phone.length;
    } else {
      const caretLocation = event?.target?.selectionStart ?? 0;
      const digitsBeforeCaret = getDigitsBeforeCaret(caretLocation, phone);

      // Update the phone number's formatting and then determine the new
      // caret location.
      phone = new AsYouType().input(phone);
      newCaretLocation = this.getNewCaretLocation(digitsBeforeCaret, phone);
    }

    // Configure a caret correction 'callback' for running after the component
    // has been rerendered.
    const { target } = event;
    this.caretCorrection = () => {
      target.setSelectionRange(newCaretLocation, newCaretLocation);
    };

    this.onChange(phone, this.state.extension);
  }

  onExtensionChange(event: { target: { value: string } }) {
    let extension = event?.target?.value ?? '';
    // remove any non numeric characters from the extension
    extension = extension.replace(/\D/g, '');
    this.onChange(this.state.phone, extension);
  }

  onChange(phone: string, extension: string) {
    // build an E.123 formatted number with the extension
    const formattedNumber = extension ? `${phone} ext. ${extension}` : phone;
    this.setState({ phone, extension });

    let payload;
    if (formattedNumber) {
      payload = {
        number: formattedNumber,
        isValid: isValidNumber(formattedNumber),
      };
    }

    this.props.onChange(payload);
  }

  // Determines the new location of the caret/cursor by ensuring
  // the proper number of digits still preceed it.
  getNewCaretLocation(digitsBeforeCaret: number, phone: string) {
    let digitsSeen = 0;
    let caretPosition = 0;
    const characters = phone.split('');

    for (let i = 0; i < characters.length; i++) {
      if (characters[i].match(/\d|\+/)) {
        digitsSeen++;
        if (digitsSeen === digitsBeforeCaret) {
          caretPosition = i + 1; // place the caret immediately after
          break;
        }
      }
    }

    // If the user had pressed the Delete key (i.e. removing digits from
    // left to right), we should place the cursor after any trailing white
    // space to make the delete experience feel more natural.
    if (this.wasDeletePressed && characters[caretPosition] === ' ') {
      caretPosition++;
    }

    return caretPosition;
  }

  parseInitialPhoneNumber(number: string): any {
    if (!number) {
      return {};
    }

    // Assume a fallback country code of US in case the number isn't in
    // international format...because 'Murica? The output of parseNumber will be
    // an empty object if it fails due to an invalid phone number format.
    let parsedPhone = parseNumber(number, {
      defaultCountry: 'US',
      extended: false,
    });
    if (number && isEmptyObject(parsedPhone)) {
      parsedPhone = parseInvalidPhoneNumber(this.props.initialValue);
    }
    return parsedPhone;
  }

  render() {
    const {
      disableBsStyling,
      disableStatus,
      className,
      countrySelectLabel,
      countrySelectClassName,
      countrySelectMenuStyle,
      phoneTextInputLabel,
      phoneTextInputClassName,
      extensionTextInputLabel,
      extensionTextInputClassName,
      selectedSelect,
      countryGroups,
      style,
    } = this.props;
    const { countryCode, phone, extension } = this.state;
    const countrySelectOptions = countryGroups ? buildCountryList(countryGroups) : defaultCountryGroups;
    const countrySelectValue = findMatchingCountrySelectOption(countrySelectOptions, countryCode);

    // This lets disableStatus override disableBsStyling while still allowing backwards compatability
    const status = disableStatus === undefined ? !disableBsStyling : !disableStatus;

    return (
      <div className={cx('crc-phone', phoneCss, className)} style={style} data-testid={this.props['data-testid']}>
        <SelectWrapper
          // This is the v2+ way of handling custom styling for various parts of the select
          components={{
            Option: ({ data, ...props }: any) => (
              <components.Option {...props}>
                <CustomPhoneSelect {...data} {...props} />
              </components.Option>
            ),
            SingleValue: ({ data, ...props }: any) => (
              <components.SingleValue {...props}>
                <CustomPhoneSelect {...data} {...props} />
              </components.SingleValue>
            ),
          }}
          name="country"
          selectedSelect={selectedSelect || Select}
          value={countrySelectValue}
          onChange={this.onChangeCountryCode}
          options={countrySelectOptions}
          label={countrySelectLabel || 'Country Code...'}
          className={cx(phoneCountryCss, countrySelectClassName)}
          menuContainerStyle={countrySelectMenuStyle || {}}
        />
        <TextField
          name="phone"
          label={phoneTextInputLabel || 'Telephone (Allowed characters: 0-9)'}
          value={phone}
          onChange={this.onPhoneNumberChange}
          onFocus={e => e.target.setSelectionRange(phone.length, phone.length)}
          onKeyDown={this.onPhoneNumberKeyPress}
          type="tel"
          className={cx(phoneNumberCss, phoneTextInputClassName)}
          disabled={!countryCode}
          status={status && countryCode && !isValidNumber(phone) ? 'error' : undefined}
          ref={this.phoneNumberRef}
        />
        <TextField
          name="extension"
          label={extensionTextInputLabel || 'Ext.'}
          value={extension}
          onChange={this.onExtensionChange}
          type="tel"
          className={cx(phoneExtensionCss, extensionTextInputClassName)}
          disabled={!countryCode}
        />
      </div>
    );
  }
}
