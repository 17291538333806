/* eslint-disable react/prop-types */

import React from 'react';
import SortIndicatorDownSvg from '../../icons/SortIndicatorDownSvg';
import SortIndicatorUpSvg from '../../icons/SortIndicatorUpSvg';
import SortIndicatorSvg from '../../icons/SortIndicatorSvg';
import { SortKey } from '../types';
import { shale, slate } from '../../colors';

export interface SortIndicatorProps {
  currentSort?: SortKey;
  accessor?: string;
}

const SortIndicator: React.FC<SortIndicatorProps> = ({ currentSort, accessor }) => {
  const iStyle: React.CSSProperties = {
    marginLeft: '5px',
  };

  if (currentSort && currentSort.key === accessor) {
    if (currentSort && currentSort.desc) {
      return <SortIndicatorUpSvg size="8" color={shale} style={iStyle} />;
    }
    return <SortIndicatorDownSvg size="8" color={shale} style={iStyle} />;
  }
  return <SortIndicatorSvg size="8" color={slate} style={iStyle} />;
};

export { SortIndicator };
