import React, { HTMLProps } from 'react';
import * as CSS from 'csstype';
import { cx } from '@emotion/css';
import spacing from './theme/spacing';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';

export interface GridProps extends HTMLProps<HTMLDivElement>, PublicComponentProps {
  /* Shorthand convenience prop to generate a number of equally sized columns */
  columns: number;

  /* Constrain gap properties to given list of spacing tokens */
  gap?: keyof typeof spacing;
  columnGap?: keyof typeof spacing;
  rowGap?: keyof typeof spacing;

  gridArea?: CSS.GridAreaProperty;
  gridAutoColumns?: CSS.GridAutoColumnsProperty<number | string>;
  gridAutoFlow?: CSS.GridAutoFlowProperty;
  gridAutoRows?: CSS.GridAutoRowsProperty<number | string>;
  gridColumnEnd?: CSS.GridColumnEndProperty;
  gridColumnStart?: CSS.GridColumnStartProperty;
  gridColumn?: CSS.GridColumnProperty;
  gridRowEnd?: CSS.GridRowEndProperty;
  gridRowStart?: CSS.GridRowStartProperty;
  gridRow?: CSS.GridRowProperty;
  gridTemplateAreas?: CSS.GridTemplateAreasProperty;
  gridTemplateColumns?: CSS.GridTemplateColumnsProperty<number | string>;
  gridTemplateRows?: CSS.GridTemplateRowsProperty<number | string>;
  gridTemplate?: CSS.GridTemplateProperty;
  grid?: CSS.GridProperty;
}

export const Grid = ({
  className,
  gridArea,
  gridAutoColumns,
  gridAutoFlow,
  gridAutoRows,
  gridColumnEnd,
  gridColumnStart,
  gridColumn,
  gridRowEnd,
  gridRowStart,
  gridRow,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
  gridTemplate,
  gap,
  columnGap,
  rowGap,
  columns,
  style,
  children,
  ...restProps
}: GridProps) => {
  const gridStyle = {
    display: 'grid',
    gridGap: gap && cvar(gap),
    gridTemplateColumns: gridTemplateColumns || (columns && `repeat(${columns} 1fr)`),
    columnGap: columnGap && cvar(columnGap),
    rowGap: rowGap && cvar(rowGap),
    gridArea,
    gridAutoColumns,
    gridAutoFlow,
    gridAutoRows,
    gridColumnEnd,
    gridColumnStart,
    gridColumn,
    gridRowEnd,
    gridRowStart,
    gridRow,
    gridTemplateAreas,
    gridTemplateRows,
    gridTemplate,
  };

  return (
    <div className={cx('crc-grid', className)} style={{ ...gridStyle, ...style }} {...restProps}>
      {children}
    </div>
  );
};
