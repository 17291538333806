import { css, cx } from '@emotion/css';
import React, { ComponentPropsWithoutRef, useEffect, useRef, useState } from 'react';
import useId from '@accessible/use-id';
import * as colors from '../colors';
import cvar from '../theme/cvar';
import CloseSvg from '../icons/CloseSvg';
import { CheckSvg } from '../icons/CheckSvg';
import PencilSvg from '../icons/PencilSvg';
import { useFeatureFlags } from '../FeatureFlags';

export type TextSize = 'default' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'small';

type SaveCancelMouseOrKeyboardEvent =
  | React.MouseEvent<HTMLButtonElement>
  | React.KeyboardEvent<HTMLTextAreaElement>
  | React.KeyboardEvent<HTMLInputElement>;

type InlineEditBaseProps = {
  label?: string;

  value?: string;

  /**
   * Specifies the minimum width of the control.  Defaults to 130px.
   */
  minWidth?: number;

  /**
   * Provides an optional override for localized warning messages for required fields
   */
  requiredWarningMessage?: string;

  /**
   * Determines the size of both the display text and the edit text.
   */
  size?: TextSize;

  /**
   * If provided and onChange is not provided, will be called on each text change to validate the input.
   */
  validateInput?: (text: string) => string | null;

  /**
   * Callback that fires on a successful save of the value in the input. Provides the input's value and name.
   */
  onSave?: (inputTarget: { value?: string; name?: string }, e: SaveCancelMouseOrKeyboardEvent) => void;

  /**
   * If provided this callback fires when the user cancels the changes. Will provide the most recent changes, when the cancel happened.
   */
  onCancel?: (inputTarget: { value?: string; name?: string }, e: SaveCancelMouseOrKeyboardEvent) => void;

  /**
   * Optional callback to be notified when the editing state changes
   */
  onEditStateChange?: (isEditing: boolean) => void;
};

type InputProps = Omit<ComponentPropsWithoutRef<'input'>, 'size' | 'type' | 'value'>;

export type InlineEditProps = InlineEditBaseProps &
  (
    | ({
        type?: 'text';
      } & InputProps)
    | ({
        type: 'text' | 'number' | 'password' | 'url' | 'search' | 'email' | 'tel';
      } & InputProps)
    | ({
        type: 'textarea';
      } & Omit<ComponentPropsWithoutRef<'textarea'>, 'value'>)
  );

const inlineEditInactiveTextColorVariable = '--crc-inline-edit-inactive-text-color';
const inlineEditBorderVariable = '--crc-inline-edit-border';

const validationMessageStyles = css`
  color: ${cvar('color-background-error')};
  margin-top: 4px;
  font-size: 12px;
`;

const rootStyles = css`
  ${`${inlineEditInactiveTextColorVariable}: ${colors.cobalt.base};`}
  ${`${inlineEditBorderVariable}: ${cvar('color-inline-edit-border')};`}
`;

const disabledStyles = css`
  ${`${inlineEditInactiveTextColorVariable}: ${colors.disabled.darker};`}
  ${`${inlineEditBorderVariable}: ${colors.disabled.darker};`}
`;

const marginBottomStyles = css`
  margin-bottom: 20px;
`;

const resetButtonStyles = css`
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
`;

const editButtonStyles = css`
  display: flex;
  align-items: center;
  color: var(${inlineEditInactiveTextColorVariable});
  padding: 4px;
  border-bottom: 1px dashed var(${inlineEditBorderVariable});
  text-align: left;
  /* font size * line height + padding + border */
  min-height: calc(1em * var(--crc-inline-edit-line-height) + 8px + 1px);

  &:not(:disabled):hover {
    cursor: pointer;
    background-color: ${cvar('color-button-secondary-hover')};

    > svg {
      display: inline-block;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  > svg {
    display: none;
    margin-left: 8px;
  }
`;

const inputStyles = css`
  border: 0;
  padding: 4px;
  border-bottom: 1px solid var(${inlineEditBorderVariable});

  &::placeholder {
    color: var(${inlineEditInactiveTextColorVariable});
  }

  &:focus {
    outline: none;
  }
`;

const measureTextStyles = css`
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
  top: 0;
  left: 0;
  padding: 4px;
  line-height: 1.5;
  height: 0;
  white-space: pre;
  overflow-x: scroll;

  > svg {
    margin-left: 8px;
  }
`;

const inputWrapperStyles = css`
  display: flex;
  align-items: flex-end;
`;

const inputButtonStyles = css`
  display: inline-flex;
  align-items: center;
  background: ${cvar('color-button-default')};
  color: ${cvar('color-inline-edit-border')};
  font-size: 14px;
  border-radius: 2px;
  border: none;
  padding: 5px;
  margin-bottom: 1px;
  box-shadow: 0px 1px 2px 0px rgba(58, 65, 76, 0.5);

  &:hover {
    cursor: pointer;
    background-color: ${cvar('color-button-secondary-active')};
  }
`;

const labelStyles = css`
  position: relative;
  display: block;
  color: ${cvar('color-text-label')};
  font-size: 14px;
  line-height: 1.5;
`;

const redAsterixStyles = css`
  color: ${cvar('color-required-asterisk')};
`;

const sizeDefaultsStyles = css`
  margin-top: 0;
  margin-bottom: 0;
  line-height: var(--crc-inline-edit-line-height);
`;

const textStylesMap: Record<TextSize, string> = {
  default: css`
    --crc-inline-edit-line-height: 1.5;
    font-size: 14px;
  `,
  small: css`
    --crc-inline-edit-line-height: 1.5;
    font-size: 85%;
  `,
  h1: css`
    --crc-inline-edit-line-height: 1.15;
    font: ${cvar('text-h1')};
  `,
  h2: css`
    --crc-inline-edit-line-height: 1.15;
    font: ${cvar('text-h2')};
  `,
  h3: css`
    --crc-inline-edit-line-height: 1.15;
    font: ${cvar('text-h3')};
  `,
  h4: css`
    --crc-inline-edit-line-height: 1.15;
    font: ${cvar('text-h4')};
  `,
  h5: css`
    --crc-inline-edit-line-height: 1.15;
    font: ${cvar('text-h5')};
  `,
  h6: css`
    --crc-inline-edit-line-height: 1.15;
    font: ${cvar('text-h6')};
  `,
};

const buttonsWrapperStyles = css`
  margin-left: 8px;
  display: flex;
  gap: 4px;
`;

export function InlineEdit({
  id,
  label,
  name,
  value,
  placeholder,
  onSave,
  onCancel,
  minWidth = 130,
  required,
  requiredWarningMessage = 'This Field is Required',
  size = 'default',
  validateInput,
  onEditStateChange,
  ...props
}: InlineEditProps) {
  const { v17_noOuterSpacing } = useFeatureFlags();
  const [showInput, setShowInput] = useState(false);
  const [localValue, setLocalValue] = useState(() => value ?? '');
  const [committedValue, setCommittedValue] = useState(() => localValue);
  const editButtonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const measureTextRef = useRef<HTMLDivElement>(null);
  const [inputWidth, setInputWidth] = useState(() => editButtonRef.current?.offsetWidth ?? 0);
  const inputId = useId(id);
  const [validationMessage, setValidationMessage] = useState<string>();

  function save(e: SaveCancelMouseOrKeyboardEvent) {
    if (!inputRef.current?.checkValidity()) {
      return;
    }

    setCommittedValue(localValue);
    setShowInput(false);
    onSave?.({ value: committedValue, name }, e);
  }

  function cancel(e: SaveCancelMouseOrKeyboardEvent) {
    setLocalValue(committedValue);
    setShowInput(false);
    onCancel?.({ value: committedValue, name }, e);
  }

  const previousIsValid = useRef(true);
  function validate(value: string) {
    const isValid = (inputRef.current?.checkValidity() ?? true) && validateInput?.(value) == null;
    previousIsValid.current = isValid;

    const htmlIsValid = inputRef.current?.checkValidity() ?? true;
    const customIsValid = validateInput?.(value) == null;

    if (!htmlIsValid) {
      if (inputRef.current?.validity.valueMissing) {
        setValidationMessage(requiredWarningMessage);
      }
    }

    const customValidationMessage = validateInput?.(value);
    if (customValidationMessage) {
      setValidationMessage(customValidationMessage);
    }

    if (htmlIsValid && customIsValid) {
      setValidationMessage('');
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) {
    e.stopPropagation();
    if (e.key === 'Enter') {
      save(e);
    }
    if (e.key === 'Escape') {
      cancel(e);
    }
  }

  useEffect(() => {
    setInputWidth(editButtonRef.current?.offsetWidth ?? 0);
  }, []);

  useEffect(() => {
    setInputWidth(measureTextRef.current?.scrollWidth ?? 0);
  }, [localValue, size, minWidth]);

  useEffect(() => {
    if (showInput && props.type === 'textarea' && inputRef.current && inputRef.current.textContent) {
      // Set the cursor to the end of the text and scroll to the end
      inputRef.current.setSelectionRange(inputRef.current.textContent?.length, inputRef.current.textContent.length);
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
    }
  }, [props.type, showInput]);

  const onEditStateChangeRef = useRef(onEditStateChange);
  useEffect(() => {
    onEditStateChangeRef.current = onEditStateChange;
  }, [onEditStateChange]);
  useEffect(() => {
    onEditStateChangeRef.current?.(showInput);
  }, [showInput]);

  useEffect(() => {
    setLocalValue(value ?? '');
    setCommittedValue(value ?? '');
  }, [value]);

  const sizeStyles = cx(textStylesMap[size], sizeDefaultsStyles);

  let inputOrTextAreaElement: JSX.Element;
  if (props.type === 'textarea') {
    const { type, onChange, onBlur, onKeyDown, ...textAreaProps } = props;
    inputOrTextAreaElement = (
      <textarea
        {...textAreaProps}
        id={inputId}
        ref={inputRef as React.RefObject<HTMLTextAreaElement>}
        className={cx(sizeStyles, inputStyles)}
        style={{ width: inputWidth, minWidth }}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        placeholder={placeholder}
        value={localValue}
        name={name}
        required={required}
        onBlur={e => {
          validate(e.currentTarget.value);
          onBlur?.(e);
        }}
        onChange={e => {
          setLocalValue(e.currentTarget.value);
          validate(e.currentTarget.value);
          onChange?.(e);
        }}
        onKeyDown={e => {
          handleKeyDown(e);
          onKeyDown?.(e);
        }}
      />
    );
  } else {
    const { onChange, onBlur, onKeyDown, ...inputProps } = props;
    inputOrTextAreaElement = (
      <input
        {...inputProps}
        id={inputId}
        ref={inputRef as React.RefObject<HTMLInputElement>}
        className={cx(sizeStyles, inputStyles)}
        style={{ width: inputWidth, minWidth }}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        placeholder={placeholder}
        value={localValue}
        name={name}
        required={required}
        onBlur={e => {
          validate(e.currentTarget.value);
          onBlur?.(e);
        }}
        onChange={e => {
          setLocalValue(e.currentTarget.value);
          validate(e.currentTarget.value);
          onChange?.(e);
        }}
        onKeyDown={e => {
          handleKeyDown(e);
          onKeyDown?.(e);
        }}
      />
    );
  }

  const children = showInput ? (
    <div className={inputWrapperStyles}>
      {inputOrTextAreaElement}
      <div className={buttonsWrapperStyles}>
        <button className={inputButtonStyles} onClick={save} aria-label="Save">
          <CheckSvg color={cvar('color-inline-edit-border')} />
        </button>
        <button className={inputButtonStyles} onClick={cancel} aria-label="Cancel">
          <CloseSvg color={cvar('color-inline-edit-border')} width="1em" height="1em" />
        </button>
      </div>
    </div>
  ) : (
    <button
      className={cx(resetButtonStyles, sizeStyles, editButtonStyles)}
      onClick={() => setShowInput(true)}
      style={{ minWidth }}
      ref={editButtonRef}
      disabled={props.disabled}
    >
      {localValue || placeholder}
      {(label === '' || label == null) && required && <sup className={redAsterixStyles}>*</sup>}
      <PencilSvg size={'14px'} color={cvar('color-inline-edit-border')} />
    </button>
  );

  return (
    <div className={cx(rootStyles, props.disabled && disabledStyles, !v17_noOuterSpacing && marginBottomStyles)}>
      {label && (
        <label className={labelStyles} htmlFor={inputId}>
          {label}
          {required && <sup className={redAsterixStyles}>*</sup>}
        </label>
      )}
      {children}
      {validationMessage && <p className={validationMessageStyles}>{validationMessage}</p>}
      <div ref={measureTextRef} className={cx(sizeStyles, measureTextStyles)} style={{ minWidth }}>
        {localValue || placeholder}
        {(label === '' || label == null) && required && <sup className={redAsterixStyles}>*</sup>}
        <PencilSvg size={'14px'} color={cvar('color-inline-edit-border')} />
      </div>
    </div>
  );
}
