import React from 'react';
import { Item, RoutingConfigurationDetails } from '../../types';
import { cimpressTechnology } from '../../constants';

const currentAndInitiatedDecision = ({
  initiatedDecisionLink,
  currentDecisionLink,
  userProfile,
}: {
  initiatedDecisionLink?: string | null;
  currentDecisionLink?: string | null;
  userProfile?: any;
}) => {
  if (!currentDecisionLink || !userProfile) {
    return;
  }

  const userAccountId = userProfile['https://claims.cimpress.io/account'];

  if (initiatedDecisionLink && currentDecisionLink && userAccountId === cimpressTechnology) {
    return (
      <div className="decisionLinks">
        <a href={initiatedDecisionLink} rel="noreferrer" target="_blank" style={{ paddingRight: '25px' }}>
          Initiated Decision
        </a>
        <a href={currentDecisionLink} rel="noreferrer" target="_blank">
          Current Decision
        </a>
      </div>
    );
  } else if (currentDecisionLink) {
    return (
      <div className="decisionLinks">
        <a href={currentDecisionLink} rel="noreferrer" target="_blank">
          Current Decision
        </a>
      </div>
    );
  }
  return;
};

const RoutingStrategyLinkWrapper = ({ url, children }: { url: string; children: JSX.Element }) => {
  if (!url) {
    return children;
  }

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => e.stopPropagation()} // don't close accordion on link click
    >
      {children}
    </a>
  );
};

export const RoutingDetails = ({
  item,
  buyer,
  configuration,
  initiatedDecisionLink,
  currentDecisionLink,
  userProfile,
}: {
  item: Item | undefined;
  buyer?: string | null;
  configuration?: RoutingConfigurationDetails;
  initiatedDecisionLink?: string | null;
  currentDecisionLink?: string | null;
  userProfile?: any;
}): JSX.Element | null => {
  if (!item) {
    return null;
  }

  const { quantity, localPromisedArrivalDate } = item;

  // This check is so that we wait to load the details until
  // we have the primary information; otherwise, there's a lot of
  // changes popping into the UI that cause layout shift
  if (!quantity || !localPromisedArrivalDate) {
    return null;
  }

  return (
    <>
      <h2>Routing Request Details</h2>
      <div className="suspended-orders-item option grid">
        <div>Quantity:</div>
        <div>{quantity}</div>
        <div>Promised Arrival Date:</div>
        <div>{localPromisedArrivalDate}</div>
        {buyer ? (
          <>
            <div>Buyer:</div> <div>{buyer}</div>
          </>
        ) : null}
        {configuration?.name && configuration?.href ? (
          <>
            <div>Configuration:</div>
            <div>
              <RoutingStrategyLinkWrapper url={configuration.href}>
                <span>{configuration.name}</span>
              </RoutingStrategyLinkWrapper>
            </div>
          </>
        ) : null}
        {currentAndInitiatedDecision({ initiatedDecisionLink, currentDecisionLink, userProfile })}
      </div>
    </>
  );
};
