import * as React from 'react';
import { slate } from '../colors';

/* eslint-disable max-len */

export interface PencilSvgProps {
  color?: string;
  size?: string;
  className?: string;
}

const PencilSvg = ({ size = '16px', color = slate, className }: PencilSvgProps) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    color={color}
  >
    <path d="M6.4,22c0.2,0.2,0.2,0.4,0,0.6l-1.2,1.2C5.1,23.9,4.9,24,4.8,24H0.5C0.2,24,0,23.8,0,23.5v-4.3c0-0.1,0.1-0.3,0.2-0.4  l1.2-1.2c0.2-0.2,0.5-0.2,0.6,0l0.6,0.6l-1.3,1.3c-0.1,0.1-0.1,0.2-0.1,0.3v1h1.7c0.2,0,0.3,0.2,0.3,0.3v1.7h1c0.1,0,0.2,0,0.3-0.1  l1.3-1.3L6.4,22z M19.4,9.3c0.1,0.1,0.1,0.2,0,0.3l-12,12c-0.1,0.1-0.2,0.1-0.3,0l-4.7-4.7c-0.1-0.1-0.1-0.2,0-0.3l12-12  c0.1-0.1,0.2-0.1,0.3,0L19.4,9.3z M14.9,6.7c-0.1-0.1-0.3-0.1-0.5,0L4.2,16.9c-0.1,0.1-0.1,0.3,0,0.5c0.1,0.1,0.2,0.1,0.2,0.1  c0.1,0,0.2,0,0.2-0.1L14.9,7.2C15.1,7,15.1,6.8,14.9,6.7z M23.6,3.4l-3.1-3c-0.5-0.5-1.4-0.5-1.9,0l-2.3,2.3c-0.1,0.1-0.1,0.2,0,0.3  L21,7.7c0.1,0.1,0.2,0.1,0.3,0l2.2-2.2C24.1,4.9,24.1,4,23.6,3.4z" />
  </svg>
);

export default PencilSvg;
