type VersionsResponse = {
  _links: {
    fulfillmentConfiguration: {
      name: string;
    };
  };
  versionId: string;
};

export async function getFcUiLink(versionsURL: string, accessToken: string): Promise<string> {
  const res = await fetch(versionsURL, { headers: { Authorization: `Bearer ${accessToken}` }, method: 'GET' });

  const fcUiBaseUrl = `https://fulfillmentconfigurations.cimpress.io`;
  if (res.ok) {
    const versionsData = (await res.json()) as unknown as VersionsResponse;
    if (versionsData?._links?.fulfillmentConfiguration?.name && versionsData?.versionId) {
      return `${fcUiBaseUrl}/fulfillmentconfiguration/${versionsData._links.fulfillmentConfiguration.name}/versions/${versionsData?.versionId}`;
    }
  }
  return fcUiBaseUrl;
}
