import { ExistingPick, InvalidConsideredOption, Option, OptionEqualityHandler, ValidConsideredOption } from '../types';

export const isOptionEqualTo: OptionEqualityHandler = (option1, option2) => {
  const option1AsConsideredOption = convertOptionConsideredOption(option1);
  const option2AsConsideredOption = convertOptionConsideredOption(option2);

  return (
    option1AsConsideredOption.referenceId === option2AsConsideredOption.referenceId &&
    option1AsConsideredOption.fulfillerId === option2AsConsideredOption.fulfillerId &&
    (option1AsConsideredOption as any).fulfillmentConfigurationUrl ===
      (option2AsConsideredOption as any).fulfillmentConfigurationUrl
  );
};

const convertOptionConsideredOption: (
  option: Option | ExistingPick,
) => InvalidConsideredOption | ValidConsideredOption = (option: Option | ExistingPick) => {
  return {
    optionId: (option as InvalidConsideredOption | ValidConsideredOption)?.optionId || '',
    fulfillerId: option.fulfillerId,
    fulfillmentConfigurationUrl: (option as Option)?._links?.fulfillmentConfiguration?.href,
    productConfigurationUrl:
      (option as Option)?._links?.productConfiguration?.href ||
      (option as InvalidConsideredOption | ValidConsideredOption).productConfigurationUrl,
    referenceId: (option as Option | InvalidConsideredOption | ValidConsideredOption).referenceId,
    problem: (option as InvalidConsideredOption | ValidConsideredOption)?.problem || undefined,
    rank: (option as Option)?.rank || '',
  };
};
