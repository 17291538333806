import React from 'react';
import './SuspendedOrdersItemHeaderInfo.css';
import { FailedDecision, Item, SuccessfulDecision } from '../../types';

export type SuspendedOrdersItemHeaderInfoProps = {
  item: Item;
  decision: SuccessfulDecision | FailedDecision | null;
  attemptedDecisionFetch: boolean;
};

const SuspendedOrdersItemHeaderInfo: React.FC<SuspendedOrdersItemHeaderInfoProps> = ({
  item,
  decision,
  attemptedDecisionFetch,
}) => {
  const { orderedSkuCode, merchantProductName } = item;

  const mcpskuColored = attemptedDecisionFetch ? (
    !decision || (decision as FailedDecision).problem ? (
      <div className="mcpsku-failure">{orderedSkuCode}</div>
    ) : (
      <div className="mcpsku-success">{orderedSkuCode}</div>
    )
  ) : (
    <div className="mcpsku-unfetched">{orderedSkuCode}</div>
  );

  return (
    <div className="suspended-orders-item-header-info">
      <div className="col-flex spread">
        {mcpskuColored}
        <div>
          <strong>Product Name:</strong> {merchantProductName}
        </div>
      </div>
    </div>
  );
};

export default SuspendedOrdersItemHeaderInfo;
