import React from 'react';
import SuspendedOrdersItemOption from './SuspendedOrdersItemOption';
import './SuspendedOrdersItemDetail.css';
import { Environment, ExistingPick, Item, Option, OptionEqualityHandler } from '../../types';
import { OptionSelectionHandler } from './SuspendedOrdersItem';

export type SuspendedOrdersItemOptionsProps = {
  accessToken: string;
  environment: Environment;
  options: Option[];
  item: Item;
  existingPick: ExistingPick | null;
  chosenOptionId: string | null;
  onOptionSelection: OptionSelectionHandler | undefined;
  isOptionEqualTo: OptionEqualityHandler;
};

const SuspendedOrdersItemOptions: React.FC<SuspendedOrdersItemOptionsProps> = ({
  accessToken,
  environment,
  options,
  item,
  existingPick,
  chosenOptionId,
  onOptionSelection,
  isOptionEqualTo,
}) => {
  if (!onOptionSelection && existingPick) {
    // what exactly does this check?
    const firstOption = options.filter((option) => {
      return isOptionEqualTo(option, existingPick);
    });
    const optionsWithoutExistingPick = options.filter((option) => {
      return !isOptionEqualTo(option, existingPick);
    });

    options = firstOption.concat(optionsWithoutExistingPick);
  }

  // This var is typically config with clients under it, not sure if that is too much abstraction
  // for using it in one file?
  const clientUrls =
    environment === 'prd'
      ? {
          fcs: 'https://fulfillmentconfigurations.cimpress.io/',
          equivalency: `https://noms.lynx.cimpress.io/equivalency/${item.orderedSkuCode}`,
          productManager: `https://productmanager-v2.products.cimpress.io/product-details/${item.orderedSkuCode}`,
        }
      : {
          fcs: 'https://int.fulfillmentconfigurations.cimpress.io/',
          equivalency: `https://noms.lynx.cimpress.io/equivalency/${item.orderedSkuCode}`,
          productManager: `https://productmanager-v2.products.cimpress.io/product-details/${item.orderedSkuCode}`,
        };

  return (
    <div className="suspended-orders-item-options">
      <div>
        {options.length ? (
          options.map((option, index) => {
            return (
              <SuspendedOrdersItemOption
                environment={environment}
                accessToken={accessToken}
                key={`item-option-${index}`}
                chosenOptionId={chosenOptionId}
                option={option}
                item={item}
                existingPick={existingPick}
                selectPickMe={onOptionSelection}
                isOptionEqualTo={isOptionEqualTo}
              />
            );
          })
        ) : (
          // If attemptedDecisionFetch=true
          <div className="no-options">
            <p>
              There are no options available for routing this item. Please setup options through one of the following
              approaches:
            </p>
            <ul>
              <li>
                <a target="_blank" rel="noreferrer" href={clientUrls.equivalency}>
                  Add Product Equivalency (Legacy)
                </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href={clientUrls.fcs}>
                  Associate this product to a Fulfillment Configuration
                </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href={clientUrls.productManager}>
                  Add a Fulfiller to the Fulfilled Product (Legacy)
                </a>
              </li>
            </ul>
            <p>
              For help configuring options or if you still see this message after configuring options, please contact
              the{' '}
              <a href="https://cimpress.slack.com/archives/C5XG09F6J" target="_blank" rel="noreferrer">
                #mcp-support-pim
              </a>{' '}
              channel on Slack.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuspendedOrdersItemOptions;
